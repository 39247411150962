import CompletedDeliveriesCategory from "../../../components/Categories/CompletedDeliveries"
import DashboardLayout from "../../../layout/DashboardLayout"



const CompletedDeliveriesChild = () => (
    <DashboardLayout>
        <CompletedDeliveriesCategory/>
    </DashboardLayout>
)


export default CompletedDeliveriesChild