import { useState } from "react"
import { useDispatch } from "react-redux"
import { useLocation, useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import { updateDriverApplication } from "../../api/dashboard/applicationApi"
import { getAllDriverApplications } from "../../redux/Dashboard/DriverAction"


const useRequest = (backNavigationLink) => {
    const location = useLocation()
    const id = location.state.id
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [approve, setApproval] = useState(false)
    const [decline, setDecline] = useState(false)
    const [load, setLoad] = useState(false)

    const handleApproval = async (id) => {
        setLoad(true)        
        try {
            const data = {
                verificationStatus: "ACCEPTED"
            }
            const response = await updateDriverApplication(id, data)
            
            if (response) {
                dispatch(getAllDriverApplications())
                setTimeout(() => {
                    navigate(backNavigationLink)
                    toast.success("driver application accepted")
                }, 1000)
            }
        } catch (error) {
            toast.error("something went wrong")
            setLoad(false)
        }
    }


    const handleDecline = async (id) => {
        setLoad(true)
        try {
            const data = {
                verificationStatus: "DECLINED"
            }
            
            const response = await updateDriverApplication(id, data)

            if (response) {
                dispatch(getAllDriverApplications())
                setTimeout(() => {
                    navigate(backNavigationLink)
                    toast.success("driver application accepted")
                }, 1000)
            }
        } catch (error) {
            if (error?.response?.status === 417) {
                dispatch(getAllDriverApplications())
                
                setTimeout(() => {
                     navigate(backNavigationLink)
                    toast.success(error?.response?.data?.message)
                }, 1000)
            } else {
                toast.error("something went wrong")
            }
            setLoad(false)
        }
    }

    const handleClose = () => {
        setApproval(() => !approve)
    }

    const handleClose2 =() => {
        setDecline(() => !decline)
    }


    return {
        id,
        approve,
        decline,
        load,
        handleClose,
        handleClose2,
        handleApproval,
        handleDecline
    }
}


export default useRequest