import React, { useState } from 'react'
import ReactDOM from 'react-dom/client'
import "./styles/index.scss"
import App from './App'
import { BrowserRouter } from "react-router-dom";
import { Provider } from 'react-redux'
import store from './redux/store';
import {PersistGate} from 'redux-persist/integration/react'
import {persistStore} from 'redux-persist'
let persistor = persistStore(store)



export const useToastHook = () => {

  const [list, setList] = useState([])
  let toastProperties = null
  const showToast = (type) => {
      switch(type) {
          case 'Success':
              toastProperties = {
                  id: 1,
                  title: "Success",
                  description: "Login Successfull",
                  backgroundColor: "#5cb85c"
              }  
              break
          case 'Error':
              toastProperties = {
                  id: 2,
                  title: "Error",
                  description: "Invalid email and password combination",
                  backgroundColor: "#d9534f"
              }  
          break
          default: 
              toastProperties= []
      }
      setList([...list, toastProperties])
  }


  return {    
      list,
      toastProperties,
      showToast,
      setList
  } 
}


const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </PersistGate>
    </Provider>
  </React.StrictMode>
)
