import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    pendingOrder: [],
    availableDriverToDelivery: [],
    pendingOrderThisDay: [],
    pendingOrderThisWeek: [],
    pendingOrderThisMonth: [],
    ongoingOrder: [],
    ongoingOrderThisDay: [],
    ongoingOrderThisWeek: [],
    ongoingOrderThisMonth: [],
    orderId: "",
    completedOrder: [],
    completedOrderThisDay: [],
    completedOrderThisWeek: [],
    completedOrderThisMonth: [],

    isLoading: false,
    error: ""
}

const OrderSlice = createSlice({
    name: "order",
    initialState,
    reducers: {
        getPendingOrder: (state) => {
            state.isLoading = true
        },

        setAvailableDriver: (state, { payload }) => {
            state.availableDriverToDelivery= payload
        },

        getPendingOrderSuccess: (state, { payload }) => {
            state.isLoading = false
            state.pendingOrder = payload
            state.error = ""
        },

        getPendingOrderSuccessThisDay: (state, { payload }) => {
            state.pendingOrderThisDay = payload
        },

        getPendingOrderSuccessThisWeek: (state, { payload }) => {
            state.pendingOrderThisWeek = payload
        },
        getPendingOrderSuccessThisMonth: (state, { payload }) => {
            state.pendingOrderThisMonth = payload
        },


        // 2
        getOngoingOrderSuccess: (state, { payload }) => {
            state.ongoingOrder = payload
        },

        getOngoingOrderSuccessThisDay: (state, { payload }) => {
            state.ongoingOrderThisDay = payload
        },

        getOngoingSuccessThisWeek: (state, { payload }) => {
            state.ongoingOrderThisWeek = payload
        },
        getOngoingOrderSuccessThisMonth: (state, { payload }) => {
            state.ongoingOrderThisMonth = payload
        },



        getCompletedOrderSuccess: (state, { payload }) => {
            state.isLoading = false
            state.completedOrder = payload
            state.error = ""
        },

        getOrderFail: (state, {payload}) => {
            state.isLoading = false
            state.error = payload
        },


        getCompletedOrderSuccessThisDay: (state, { payload }) => {
            state.completedOrderThisDay = payload
        },

        getCompletedSuccessThisWeek: (state, { payload }) => {
            state.completedOrderThisWeek = payload
        },
        getCompletedOrderSuccessThisMonth: (state, { payload }) => {
            state.completedOrderThisMonth = payload
        },

        storeOrderId: (state, {payload}) => {
            state.orderId = payload
        },


        logoutOrderData: (state) => {
            state.pendingOrder= []
            state.ongoingOrder= []
            state.completedOrder= []
            state.orderId = ""
            state.completedOrderThisDay= []
            state.completedOrderThisWeek= []
            state.completedOrderThisMonth= []
            
            // for declined app
            state.pendingOrderThisDay= []
            state.pendingOrderThisWeek= []
            state.pendingOrderThisMonth= []
            
            // for pending app
            state.ongoingOrderThisDay= []
            state.ongoingOrderThisWeek= []
            state.ongoingOrderThisMonth= []
        }
        

    }
})

export const {
    logoutOrderData,
    getPendingOrder,
    getOrderFail,
    setAvailableDriver,
    getPendingOrderSuccess,
    getPendingOrderSuccessThisDay,
    getPendingOrderSuccessThisWeek,
    getPendingOrderSuccessThisMonth,
    getOngoingOrderSuccessThisDay,
    getOngoingOrderSuccessThisWeek,
    getOngoingOrderSuccessThisMonth,
    getCompletedOrderSuccessThisDay,
    getCompletedOrderSuccessThisWeek,
    getCompletedOrderSuccessThisMonth,
    getOngoingOrderSuccess,
    getCompletedOrderSuccess,
    storeOrderId
} = OrderSlice.actions

export default OrderSlice.reducer