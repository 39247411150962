import {ToastContainer} from "react-toastify"
import 'react-toastify/dist/ReactToastify.css';
import { Routes, Route } from "react-router-dom"
import {
    AdminAuthentication,
    ApprovedApplications,
    // VerifyUser,
    UserPassword,
    CompeletedDeliveries,
    Dashboard,
    DashboardChild,
    DeclinedApplications,
    ForgetPassword,
    OngoingDeliveries,
    PendingApplications,
    PendingDeliveries,
    ResetPassword,
    Users,
    ApprovedAppChild,
    DeclinedAppChild,
    PendingAppChild,
    PendingDeliveriesChild,
    AssignDriver,
    CompletedDeliveriesChild,
    OngoingDeliveriesChild,
    Support,    
} from './pages'
import DriverInformation from "./pages/Dashboard/PendingDeliveriesPage/pending-deliveries-driver-information";




const MainRoutes = () => (
    <>
        <Routes>
            <Route path="/" element={ <AdminAuthentication /> } />
            <Route path="/forgot-password" element={<ForgetPassword />} />   
            <Route path="/reset-password" element={<ResetPassword />} />

            {/* <Route path="/verify-user" element={<VerifyUser />} /> */}
            <Route path="/verify-account" element={<UserPassword />} />

            {/* dashboard routes */}
            <Route path="/dashboard">
                <Route index element={<Dashboard />} />  
                <Route path=":id" element={<DashboardChild />} />
            </Route>

            {/* approved-appliication routes */}
            <Route path="/approved-applications">
                <Route index element={<ApprovedApplications />} />  
                <Route path=":id" element={<ApprovedAppChild />} />
            </Route>

            {/* pending-appliication routes */}
            <Route path="/pending-applications">
                <Route index element={<PendingApplications />} />  
                <Route path=":id" element={<PendingAppChild />} />
            </Route>

            {/* declined-appliication routes */}
            <Route path="/declined-applications">
                <Route index element={<DeclinedApplications />} />  
                <Route path=":id" element={<DeclinedAppChild />} />
            </Route>

            
            
            {/* pending-deliveries routes */}
            <Route path="/pending-deliveries">
                <Route index element={<PendingDeliveries />} />  
                <Route path=":id" element={<PendingDeliveriesChild />} />
                <Route path="assign-driver" element={<AssignDriver />} />
                <Route path="driver-information" element={<DriverInformation />} />
            </Route>

            {/* <Route path="/driver-information" element={<DriverInformation />} /> */}
            

            {/* ongoing-deliveries routes */}
            <Route path="/ongoing-deliveries">
                <Route index element={<OngoingDeliveries />} />  
                <Route path=":id" element={<OngoingDeliveriesChild />} />
            </Route>

            {/* completed-deliveries routes */}
            <Route path="/completed-deliveries">
                <Route index element={<CompeletedDeliveries />} />  
                <Route path=":id" element={<CompletedDeliveriesChild />} />
            </Route>

            
            <Route path="support-request" element={ <Support/> }/>

            <Route path="users" element={ <Users/> }/>
        {/* <Route path="*" element={<NotFoundPage />} /> */}
        </Routes>

        <ToastContainer/>
    </>
)


export default MainRoutes