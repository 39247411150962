import {  useState } from 'react'
import DashboardContentLayout from '../../../layout/DashboardContentTopLayout'
import { appTableHeader } from '../../../utils/data'
import { useSelector } from 'react-redux'
import DataFilterByDate from '../../DataFilter'

const DeclinedAppContent = () => {
    const { declinedApp , declinedAppToday, declinedAppThisWeek, declinedAppThisMonth } = useSelector(state => state.driver)
    const [selected, setSelected] = useState("This year")


    return (
        <>
            <DashboardContentLayout
                title="Declined Applications" 
                selected={selected}
                setSelected={setSelected}
            />

            {declinedApp ? (
                <DataFilterByDate 
                    word="declined applications"
                    routeName="declined-applications"
                    selected={selected}
                    todayData={declinedAppToday}
                    thisWeekData={declinedAppThisWeek}
                    thisMonthData={declinedAppThisMonth}
                    thisYearData={declinedApp}
                    typeOfTable={appTableHeader}
                /> 
            
            ) : "loading"}
        </>
    )
}

export default DeclinedAppContent