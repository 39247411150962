import axios from "axios"
import { setToken } from "../apiClient"

const baseURL =  "https://fastrack-app.herokuapp.com/api/"

export const instance = axios.create({
    baseURL,
    timeout: 300000,
    headers: {
        "Access-Control-Allow-Origin": "*",
        "content-type": "application/json",
        "X-Fastrack-key": `${process.env.REACT_APP_FASTRACK_KEY}`,
    }
})



export const fetchAllMobileUsersApplications = () => {
    return new Promise(async (resolve, reject) => { 
        try {               
            const accessJWT = sessionStorage.getItem("accessToken")
            
            if (!accessJWT) {
                reject("Token not found")
            }
            
            const resp = await instance.get("auth/user", setToken(accessJWT))
            resolve(resp)
        } catch ( error ) {
            reject(error)
        }
    })
}
