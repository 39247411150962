import React, { useState } from 'react'
import DashboardContentLayout from '../../../layout/DashboardContentTopLayout'
import { appTableHeader } from '../../../utils/data'
import {  useSelector } from 'react-redux'
import DataFilterByDate from '../../DataFilter'

const ApprovedAppContent = () => {
    const { approvedApp, approvedAppToday, approvedAppThisWeek, approvedAppThisMonth } = useSelector(state => state.driver)
    const [selected, setSelected] = useState("This year")

    return (
        <>
            <DashboardContentLayout
                title="Approved Applications"
                selected={selected}
                setSelected={setSelected}
            />

            {approvedApp ? (
                <DataFilterByDate 
                    word="approved applications"
                    routeName="approved-applications"
                    selected={selected}
                    todayData={approvedAppToday}
                    thisWeekData={approvedAppThisWeek}
                    thisMonthData={approvedAppThisMonth}
                    thisYearData={approvedApp}
                    typeOfTable={appTableHeader}
                />
              
            ) : "loading"}  
        </>
    )
}

export default ApprovedAppContent