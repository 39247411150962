import { useState } from 'react'
import DataRow from './DataRow'
import './index.scss'
import Pagination from './Pagination'


const DataTable = ({ APIData,  routeName,  headerName, variant, selected, setSelected }) => {
    const [tableData] = useState(APIData)    
    const [searchInput, setSearchInput] = useState('');
    // const [searchDateValue, setSearchDateValue] = useState('');
    const [filteredResults, setFilteredResults] = useState([]);

    const [currentPage, setCurrentPage] = useState(1)
    const [itemsPerPage] = useState(10)
    const [pageNumberLimit] = useState(1)
    const [maxPageNumberLimit, setMaxPageNumberLimit] = useState(1)
    const [minPageNumberLimit, setMinPageNumberLimit] = useState(0)


    const searchItems = (searchValue) => {
        setSearchInput(searchValue)    
        if (searchInput !== '') {
            const filteredData = APIData.filter((item) => {
                return Object.values(item).join('').toLowerCase().includes(searchInput.toLowerCase())
            })
            setFilteredResults(filteredData)
        }
        else {
            setFilteredResults(APIData)
        }
    }



    const pages = []
    for (let i = 1; i <= Math.ceil(tableData?.length / itemsPerPage); i++) { 
        pages.push(i)
    }

    const indexOfLastItems = currentPage * itemsPerPage
    const indexOfFirstItems = indexOfLastItems - itemsPerPage
    const currentItems = tableData?.slice(indexOfFirstItems, indexOfLastItems)


    const handleClick = (e) => {
        setCurrentPage(Number(e.target.id))
    }

    const renderPageNumbers = pages?.map(number => {
        if (number < maxPageNumberLimit + 1 && number > minPageNumberLimit) {
            
            return (
                <span
                    key={number}
                    id={number}
                    onClick={handleClick}
                    className={ currentPage === number ? "active" : null}
                >
                    {number}
                </span>
            )
        } else {
            return null
        }
    })
    
    const renderData = (rowdata) => { 
        return (
            <tbody>
                {rowdata?.map((data) => (
                    <DataRow
                        rowData={data}
                        key={data.id}
                        routeName={routeName}
                        variant={variant}
                    />
                ))}
            </tbody>
        )
    }


    const handleNext = () => {        
        setCurrentPage(currentPage + 1)
        if (currentPage + 1 > maxPageNumberLimit) {
            setMaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit)
            setMinPageNumberLimit(minPageNumberLimit + pageNumberLimit)
        }
    }

    const handlePrevious = () => {
        setCurrentPage(currentPage - 1)
        if ((currentPage - 1)%pageNumberLimit === 0) {
            setMaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit)
            setMinPageNumberLimit(minPageNumberLimit - pageNumberLimit)
        }
    }



    return (
        <>
            <div className="searchContainer">
                <img src="https://res.cloudinary.com/fastrack12/image/upload/v1661337462/search_tumu79.svg" alt="search icon" />
                <input
                    type="text"
                    placeholder="Search name or phone number"
                    onChange={(e) => searchItems(e.target.value)}
                />
            </div>
  
            <div className='tableContainer'>
                <table>
                    <thead>
                        <tr className="header">
                            {headerName?.map(header => (
                                <th key={header.id} >{ header.name }</th>
                            ))}
                        </tr>
                    </thead>

                    {/* writing a condition here to display search items
                        if the searchInput hooks component receives an value greater than 1
                        the filteredResults stored should be mapped over and displayed 
                        to the admin else return all the table data
                     */}
                    {searchInput.length > 1  ? (
                        <tbody>
                            {filteredResults?.map((rowData) => (
                                <DataRow
                                    rowData={rowData}
                                    key={rowData.id}
                                    routeName={routeName}
                                    variant={variant}
                                />
                            ))}
                        </tbody>
                    ): (
                      renderData(currentItems) 
                    )}
                </table>
            </div>

        
            <Pagination 
                currentPage={currentPage}
                handleNext={handleNext}
                handlePrevious={handlePrevious}
                renderPageNumbers={renderPageNumbers}
            />    

        </>
    ) 
}

export default DataTable