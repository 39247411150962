import PendingDelivery from '../../../components/InnerDashboardContents/PendingDeliveryContent'
import DashboardLayout from '../../../layout/DashboardLayout'



const PendingDeliveries = () => (
    <DashboardLayout>
        <PendingDelivery/>
    </DashboardLayout>
)


export default PendingDeliveries