import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import DashboardContentLayout from '../../../layout/DashboardContentTopLayout'
import { getAllCompletedDeliveries, getAllCompletedDeliveriesThisDay, getAllCompletedDeliveriesThisMonth, getAllCompletedDeliveriesThisWeek } from '../../../redux/Dashboard/OrderAction'
import { deliveriesTableHeader } from '../../../utils/data'
import DataFilterByDate from '../../DataFilter'


const CompletedDeliveriesContent = () => {
    const dispatch = useDispatch()
    const { completedOrder, completedOrderThisDay, completedOrderThisWeek, completedOrderThisMonth} = useSelector(state => state.order)
    const [selected, setSelected] = useState("Today")    

    useEffect(() => {
        dispatch(getAllCompletedDeliveries())
        dispatch(getAllCompletedDeliveriesThisDay())
        dispatch(getAllCompletedDeliveriesThisWeek())
        dispatch(getAllCompletedDeliveriesThisMonth())
    }, [dispatch])

    // console.log(completedOrder)

    
    return (
        <>
            <DashboardContentLayout
                title="Completed Deliveries" 
                selected={selected}
                setSelected={setSelected}
            />
            

            {completedOrder ? (
                <DataFilterByDate 
                    word="Completed Deliveries "
                    variant={true}
                    routeName="completed-deliveries"
                    selected={selected}
                    todayData={completedOrderThisDay}
                    thisWeekData={completedOrderThisWeek}
                    thisMonthData={completedOrderThisMonth}
                    thisYearData={completedOrder}
                    typeOfTable={deliveriesTableHeader}
                />

            ) : "loading"}  
        </>
    )
}

export default CompletedDeliveriesContent