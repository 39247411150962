import { io } from "socket.io-client"
import { useEffect, useState } from 'react'
import { useSelector } from "react-redux"

const Notifications = () => {
    const [open, setOpen] = useState(false)
    const { user } = useSelector(state => state.user)
    const [socket, setSocket] = useState(null)
    const [notifications, setNotifications] = useState([])
        
    useEffect(() => {
        setSocket(io("https://fastrack-app.herokuapp.com"))
    }, [])

    const MINUTES_MS = 5000

    useEffect(() => {
        const interval = setInterval(() => {
            socket?.emit("newUser", user)
            socket?.emit("sendNotification", {
                senderName: user.name,
                receiverName: user.name,
                type: 1
            })
        }, MINUTES_MS);
        return () => clearInterval(interval)
    }, [socket, user])

    useEffect(() => {
        socket?.on("getNotification", (data) => {
            setNotifications((prev) => [ data])
        })
    }, [socket])

    const handleMarkAsRead = () => {
        socket.emit('clearNotification', {
            senderName: null,
            receiverName:null
        })
        
        socket.on('clearNotification', (data) => {
            setNotifications([])
        })
        setOpen(false)
    }

    return (
        <div
            onMouseEnter={() => setOpen(true)}
            onMouseLeave={() => {
                setOpen(false)
            }}
            className="left"
        >
            <div
                className="iconContainer"
            >
                <img className="notification" src="https://res.cloudinary.com/fastrack12/image/upload/v1664308758/notification_xxn0x0.svg" alt="notifications icon" />
                {(notifications?.map(n => n?.notifyAdmin?.length)) > 0 && <div></div>}
            </div>
            {open && (
                <div
                    onClick={(e) => e.stopPropagation()}
                    className="notifications">      
                    <div
                        className="cancelNotifications"
                        onClick={() => setOpen(!open)}
                    >
                        <img src="https://res.cloudinary.com/fastrack12/image/upload/v1664369620/x_bkhxvy.svg" alt="close icon" />
                    </div>
                    <p className="text">Notifications</p>
                    {notifications?.map((n) => (
                        // displayNotifications(n)
                        n?.notifyAdmin?.map((n, index) => (
                            <div className='notificationContent' key={index}>
                                <div className="dot"></div>    
                                <section>
                                    <span>{n?.message}</span>

                                    <span className='time'>{n?.date}</span>
                                </section>
                                {/* <div
                                    className="dotz"
                                    onClick={ handleRead }
                                >
                                    <img src="https://res.cloudinary.com/fastrack12/image/upload/v1664369392/Vector_3_uqruhs.svg" alt="right icon" />
                                </div> */}
                            </div>    
                        ))
                        
                    ))}




                    <div style={{display: "flex", justifyContent:"center"}}>
                        {(notifications?.map(n => n?.notifyAdmin?.length)) > 0 && <button style={{ width: "70%", justifyContent: "center" }}
                            onClick={handleMarkAsRead}
                        >
                            Mark As Read
                        </button>}
                    </div>
                </div>
            )}
        </div>
    )
}

export default Notifications