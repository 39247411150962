import { onValue, ref } from "firebase/database";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";

import ChatContainer from "./ChatContainer";
import "./index.scss";
import LeftPaneChatItem from "./LeftPane";
import { db } from "../../../firebase/firebase";

const Support = () => {
  const { appUsers } = useSelector((state) => state.allUsers);
  const [selected, setSelected] = useState(false);
  const [userId, setUserId] = useState("");
  const [message, setMessage] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);

  const users = appUsers?.users;
  const drivers = appUsers?.drivers;
  const appUsersAndDrivers = users?.concat(drivers);

  // this is for changing the route of the Id
  const [selectedId, setSelectedId] = useState("1");
  const [, setLeftId] = useState("1");
  const [open, setOpen] = useState(true);
  const [selectedIndex, setSelectedindex] = useState(null);

  const handleClick = () => {
    setOpen(() => !open);
  };

  const [color, setColor] = useState({
    bg1: "white",
    bg2: "transparent",
    color1: "1E1F20",
    color2: "#6D7580",
    boxShadow1: "0px 1px 4px rgba(0, 0, 0, 0.04)",
    boxShadow2: "none",
  });

  const handleSelect = (id) => {
    if (id === "1") {
      setLeftId(id);
      setColor({
        bg1: "white",
        bg2: "transparent",
        color1: "1E1F20",
        color2: "#6D7580",
        boxShadow1: "0px 1px 4px rgba(0, 0, 0, 0.04)",
        boxShadow2: "none",
      });
    } else {
      setLeftId(id);
      setColor({
        color1: "#6D7580",
        color2: "1E1F20",
        bg1: "transparent",
        bg2: "white",
        boxShadow1: "none",
        boxShadow2: "0px 1px 4px rgba(0, 0, 0, 0.04)",
      });
    }
  };

  const fetchChats = () => {
    const dbMessage = ref(db, "messages");
    onValue(dbMessage, (snapshot) => {
      let data = [];
      snapshot.forEach((childSnapshot) => {
        const messageData = childSnapshot.val();

        // format messages coming from firebase realtime database
        const messageList = Object.keys(messageData).map((key) => ({
          ...messageData[key]?.chat,
          uid: key,
        }));


        // convert `messageList` array to object here and push into `data` empty array
        const formattedMessageList = Object.assign({}, ...messageList);
          data?.push(formattedMessageList);
        });

      // console.log("this is the formatted message List ", formattedMessageList);
      // filter through `appUsersAndDrivers` to check if there's corresponding userId with the `data` array
      const filtered = appUsersAndDrivers.filter((obj1) =>
        data.some((obj2) => obj2.userId === obj1.id)
      );


      setFilteredUsers(filtered);
    });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => fetchChats(), []);


  return (
    <>
      <div className="supportContainer">
        <div className="topContainer desktop">
          <div className="titleContainer">
            <p>Support Requestss</p>
          </div>
        </div>

        <div className="mobileView">
          {!open ? (
            <div className="topContainer">
              <div className="titleContainer">
                <p>Support Requests</p>
              </div>
            </div>
          ) : (
            <div
              style={{ display: "flex", marginTop: "50px" }}
              onClick={handleClick}
              className="goBackBtn"
            >
              <img
                src="https://res.cloudinary.com/fastrack12/image/upload/v1661459504/Vector_5_htgbvd.svg"
                alt="left arrow icon"
              />
              <p style={{ marginLeft: "5px" }}>Go back</p>
            </div>
          )}
        </div>

        <div className="chatContainer">
          <div className={`leftChatContainer ${open ? "open" : ""}`}>
            <nav className="chatNav">
              <section
                style={{
                  background: `${color.bg1}`,
                  color: `${color.color1}`,
                  boxShadow: `${color.boxShadow1}`,
                  width: "100%",
                }}
                className="selected"
                onClick={() => handleSelect("1")}
              >
                Users And Drivers
              </section>
            </nav>

            <div className="requestContainer">
              {filteredUsers?.length !== 0 ? (
                filteredUsers?.map((user, index) => (
                  <LeftPaneChatItem
                    open={open}
                    setOpen={setOpen}
                    message={message}
                    setMessage={setMessage}
                    email={user?.email}
                    setUserId={setUserId}
                    setSelected={setSelected}
                    fullName={user?.fullName}
                    key={user?.id}
                    id={user?.id}
                    phoneNumber={user?.phoneNumber}
                    status={user?.onlineStatus}
                    setSelectedId={setSelectedId}
                    index={index}
                    selectedIndex={selectedIndex}
                    setSelectedIndex={setSelectedindex}
                  />
                ))
              ) : (
                <div className="requestContent">
                  <div className="messageHighlight">
                    <p>No User Request Yet</p>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className={`rightChatContainer ${open === false ? "open" : ""}`}>
            {selected ? (
              <ChatContainer
                userId={userId}
                message={message}
                setSelectedId={setSelectedId}
                selectedId={selectedId}
              />
            ) : (
              <div className="requestContent">
                <div className="header">
                  <div className="nameContainer">
                    <div className="firstLetterContainer">SR</div>
                    <div className="profileContainer"></div>
                  </div>
                </div>
                <div className="profilePage">
                  <div className="personalInformation">
                    <p>Your Chat Will Appear Here </p>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Support;
