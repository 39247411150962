import React, {  useState } from 'react'
import {  useSelector } from 'react-redux'
import DashboardContentLayout from '../../../layout/DashboardContentTopLayout'
import { appTableHeader } from '../../../utils/data'
import DataFilterByDate from '../../DataFilter'

const PendingAppContent = () => {
    const { pendingApp, pendingAppToday, pendingAppThisWeek, pendingAppThisMonth } = useSelector(state => state.driver)
    const [selected, setSelected] = useState("Today")
    
    return (
        <>
            <DashboardContentLayout
                title="Pending Applications" 
                selected={selected}
                setSelected={setSelected}
            />

            {pendingApp  ? (    
                <DataFilterByDate 
                    word="pending applications"
                    routeName="pending-applications"
                    selected={selected}
                    todayData={pendingAppToday}
                    thisWeekData={pendingAppThisWeek}
                    thisMonthData={pendingAppThisMonth}
                    thisYearData={pendingApp}
                    typeOfTable={appTableHeader}
                />        
            ) : "loading"}
        </>
    )
}

export default PendingAppContent