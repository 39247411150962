import DeclinedAppSubcategory from "../../../components/Categories/DeclinedApp"
import DashboardLayout from "../../../layout/DashboardLayout"


const DeclinedAppChild = () => (
    <DashboardLayout>
        <DeclinedAppSubcategory/>
    </DashboardLayout>
)


export default DeclinedAppChild