import { fetchUser } from "../../api/dashboard/userApi"
import {
    getUserPending,
    getUserSuccess,
    getUserFail
} from "../../redux/Dashboard/UserSlice"

export const getUserProfile = () => async (dispatch) => {    
    try {
        dispatch(getUserPending())
        // call the api here
        const resp = await fetchUser()

        if (resp.data && resp.data.id) 
            return dispatch(getUserSuccess(resp.data))
            
        dispatch(getUserFail("User is not Found"))

    } catch(error) {
        dispatch(getUserFail(error))
    }

}
