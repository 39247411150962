import SubDeliveriesLayout from "../../../layout/CategoriesLayout/subDeliveries"
import { useLocation, useNavigate } from "react-router-dom"
import { useEffect, useState } from "react"
import ConfirmationPopup from "../../PopupModal"
import { useDispatch } from "react-redux"
import { storeOrderId } from "../../../redux/Dashboard/OrderSlice"
import { getAllAvailableDriverToDeliveries } from "../../../redux/Dashboard/OrderAction"

const PendingDeliveriesSubcategory = () => {
    const location = useLocation()    
    const dispatch = useDispatch()
    const [approve, setApprove] = useState(false)

    useEffect(() => {
        dispatch(storeOrderId(location.state.id))
        dispatch(getAllAvailableDriverToDeliveries(location.state.id))
    }, [dispatch, location])
    

    const navigate = useNavigate()

    const handleDriver = () => {
        navigate("/pending-deliveries/assign-driver")
    }
    
    const handleDelivery = () => {
        setApprove(()=> !approve)
    }

    return (
        <>
            <SubDeliveriesLayout
                backRouteName="pending-deliveries"
            >

                <div className="buttonContainer">
                    {/* <button onClick={handleDelivery}>Approve delivery</button> */}
                    <button className="decline" onClick={handleDriver}>Assign driver</button>
                </div>

            </SubDeliveriesLayout>
            

            {approve && (
                <ConfirmationPopup
                    cancelFunction={handleDelivery}
                    text="Are you sure you want to approve this delivery?"
                    cancelButtonText="Cancel"
                    confirmButtonText="Yes, approve"
                />
            )}
        </>
        
    )
}


export default PendingDeliveriesSubcategory