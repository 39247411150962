import AssignDriver from "../../../components/SubCategoriess"
import DashboardLayout from "../../../layout/DashboardLayout"



const PendindDeliveriesSubChild = () => (
    <DashboardLayout>
        <AssignDriver/>
    </DashboardLayout>
)


export default PendindDeliveriesSubChild