import AllAppSubcategory from "../../../components/Categories/AllApp"
import DashboardLayout from "../../../layout/DashboardLayout"

const DashboardChild = () => (
    <DashboardLayout>
        <AllAppSubcategory/>
    </DashboardLayout>
)


export default DashboardChild