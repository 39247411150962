import React, { useState } from 'react'
import './index.scss'
import { useNavigate } from 'react-router-dom'
import { verifyUserAccount } from '../../api/authentication/authApi'
import {toast} from 'react-toastify'
import FormInput from '../InputField/FormInput'


const AdminPassword = () => {
    const navigate = useNavigate()
    
    // to grab the id from the url when admin clicks on the email link for verification
    const queryParams = new URLSearchParams(window.location.search)
    const token = queryParams.get("token")
    
    const[loading, setLoading] = useState(false)

    const [state, setState] = useState({
        password: "",
        password_confirmation: ""
    })
    
  
    const submitFormData = async (e) => {
        e.preventDefault()
        setLoading(true)
        try {
            const resp = await verifyUserAccount(token, state)
            setLoading(false)
                    
            if (resp) navigate('/')
            toast.success("email account verified")
        } catch (error) {
            toast.error(error.response.data.message)
            setLoading(false)
        }
    }

    const handleChange = (e) => {
        const value = e.target.value
        setState({
            ...state,
            [e.target.name]: value
        })
    }

    const adminPasswordInputs = [
        {
            id: 1,
            type: "password",
            name: "password",
            placeholder: "*********",
            label: "Password",
            errorMessage: "Password should be 5-20 characters and should include 1 letter and 1 number",
            // pattern: `^(?=.*[0-9])(?=.*[a-zA-Z])[a-zA-Z0-9!@#$%^&*]{5,20}$`,
            required: false
        },

        {
            id: 2,
            type: "password",
            name: "password_confirmation",
            placeholder: "*********",
            label: "Confirm Password",
            errorMessage: "Passwords don't match",
            pattern: state.password,
            required: false
        }
    ]


    return (
        <>
            
            <form  className="form" onSubmit={submitFormData}>                              
                {/* render input Components herre */}
                {adminPasswordInputs.map((input) => (
                    <FormInput
                        key={input.id}
                        {...input}
                        value={state[input.name]}
                        onChange={handleChange}
                    />
                ))}
                
            
                <button className="nextBtn">
                    {loading ? <div className="loadingz"></div> : "Create Password"}
                </button>

            </form>
        
        </>
        

    )
}

export default AdminPassword