import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    user: {},
    isLoading: false,
    error: "",
    token: ""
}

const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        getUserPending: (state) => {
            state.isLoading = true
        },
        
        getUserSuccess: (state, { payload }) => {
            state.isLoading = false
            state.user = payload
            state.error = ""
        },

        getUserFail: (state, {payload}) => {
            state.isLoading = false
            state.error = payload
        },

        logoutUser: (state) => {
            state.error = ""
            state.token = ""
            state.user = {}
        }
    }
})

export const {logoutUser, getUserPending, getUserSuccess, getUserFail} = userSlice.actions

export default userSlice.reducer