import AllApp from '../../../components/InnerDashboardContents/AllAppContent'
import DashboardLayout from '../../../layout/DashboardLayout'

const Dashboard = () => (
    <DashboardLayout>
        <AllApp/>
    </DashboardLayout>
)


export default Dashboard