import { appTableHeader } from "../../utils/data";
import { useSelector } from "react-redux";
import AssignTable from "./AssignTable";
import EmptyTray from "../EmptyTray";
import { useNavigate } from "react-router-dom";

const AssignDriver = () => {
  const { availableDriverToDelivery } = useSelector((state) => state.order);
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <>
      <div className="topContainer">
        <div className="titleContainer">
          <p>Available Drivers</p>
        </div>
      </div>

      {availableDriverToDelivery.length !== 0 ? (
        <AssignTable
          APIData={availableDriverToDelivery}
          routeName="driver-information"
          headerName={appTableHeader}
        />
      ) : (
        <div>
          <EmptyTray text="There are no Availale Driver currently for this order" />
          <div className="buttonContainer1">
            <button className="decline" onClick={handleBack}>
              Go Back
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default AssignDriver;
