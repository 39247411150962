import axios from "axios"

export const AUTHENTICATION = {
    login: "/login",
    forgotPassword: "/forgot",
    registerAdmin: "/create",
    verifyAccount: "/verify",
    changePassword: "/change-password",
    ResetPassword: "/reset-password"
}



export const DASHBOARD = {
    profile: "/profile",
    driverApplication: "/dashboard/applications",
    singleDriverApplication: "/applications",
    updateDriverApplication: "/applications"
}

export const DELIVERIES = {
    pendingDeliveries: "/pending",
    ongoingDeliveries: "/ongoing",
    completedDeliveries: "/completed",
    assignDriver: "orders/assign",
    cancelDelivery: "/cancel"
}

export const ADMIN = {
    fetchAllAdmin: "/all-admins",
    editAdminRole: "/edit-role",
    deleteAdmin: "/delete"
}




const baseURL =  "https://fastrack-app.herokuapp.com/api/driver/order/"

export const instance = axios.create({
    baseURL,
    timeout: 300000,
    headers: {
        "Access-Control-Allow-Origin": "*",
        "content-type": "application/json",
        "X-Fastrack-key": "eyJhbGciOiJIUzI1NiJ9.ZGF2aWQ.klGLQYXMJ3dcX_hfDYXlTBbJ2WAgwtn2ti_KtGbqy7o",
    }
})

export const assignDriverToDelivery = (data) => {
    return new Promise(async (resolve, reject) => { 
        try {
            const resp = await instance.patch("/assign", data)
            resolve(resp)
        } catch(error) {
            reject(error)
        }    
    })
}


export const cancelDelivery = (orderId) => {
    return new Promise(async (resolve, reject) => {
        try{
            const resp = await instance.patch(`${DELIVERIES.cancelDelivery}/${orderId}`)
            resolve(resp)
        } catch (error) {
            reject(error)
        }
    })
}
