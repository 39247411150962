import React, { useState } from 'react'
import './index.scss'
import { forgotPassword } from '../../api/authentication/authApi'
import {toast} from 'react-toastify'
import FormInput from '../InputField/FormInput'


const ForgotPassword = () => {
    const [loading, setLoading] = useState(false)
    const [state, setState] = useState({
        email: ""
    })    
    

    const submitFormData = async (e) => {
        e.preventDefault()
        setLoading(true)
        try {
            const resp = await forgotPassword(state)
            toast.success(resp.data.message)
            setLoading(false)
        } catch (error) {
            setLoading(false)
            toast.error(error.response.data.message)
        }
    }

    const onChange = (e) => {
        setState({
            ...state,
            [e.target.name] : e.target.value
        })
    }
    
    const inputs = [
        {
            id: 1,
            type: "email",
            name: "email",
            placeholder: "janedoe@gmail.com",
            label: "Email",
            errorMessage: "email address is not valid",
            required: true
        },
    ]

    return (
        <>
         
            <form  className="form" onSubmit={submitFormData}>
                                    
                {inputs.map((input) => (
                    <FormInput
                        key={input.id}
                        {...input}
                        value={state[input.name]}
                        onChange={onChange}
                    />
                ))}

            
                <button className="nextBtn">
                    { loading ? <div className="loadingz"></div> : "Submit"}
                </button>

            </form>
        </>
        
    )
}

export default ForgotPassword