import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DashboardContentLayout from "../../../layout/DashboardContentTopLayout";
import {
  getAllPendingDeliveries,
  getAllPendingDeliveriesThisDay,
  getAllPendingDeliveriesThisMonth,
  getAllPendingDeliveriesThisWeek,
} from "../../../redux/Dashboard/OrderAction";
import { deliveriesTableHeader } from "../../../utils/data";
import DataFilterByDate from "../../DataFilter";

const PendingDeliveryContent = () => {
  const dispatch = useDispatch();
  const {
    pendingOrder,
    pendingOrderThisDay,
    pendingOrderThisWeek,
    pendingOrderThisMonth,
  } = useSelector((state) => state.order);
  const [selected, setSelected] = useState("Today");

  useEffect(() => {
    dispatch(getAllPendingDeliveries());
    dispatch(getAllPendingDeliveriesThisDay());
    dispatch(getAllPendingDeliveriesThisWeek());
    dispatch(getAllPendingDeliveriesThisMonth());
  }, [dispatch]);

  return (
    <>
      <DashboardContentLayout
        title="Pending Deliveries"
        selected={selected}
        setSelected={setSelected}
      />

      {pendingOrder ? (
        <DataFilterByDate
          word="Pending Orders"
          variant={true}
          routeName="pending-deliveries"
          selected={selected}
          todayData={pendingOrderThisDay}
          thisWeekData={pendingOrderThisWeek}
          thisMonthData={pendingOrderThisMonth}
          thisYearData={pendingOrder}
          typeOfTable={deliveriesTableHeader}
        />
      ) : (
        "loading"
      )}
    </>
  );
};

export default PendingDeliveryContent;
