import { instance, setToken } from "../apiClient"
import { ADMIN, AUTHENTICATION, DASHBOARD } from "../apiRoutes"

export const fetchUser = () => {
    return new Promise(async (resolve, reject) => { 
        try {
            const accessJWT = sessionStorage.getItem("accessToken")
            
            if (!accessJWT) {
                reject("Token not found")
            }
                        
            const res = await instance.get(DASHBOARD.profile, setToken(accessJWT))
            resolve(res.data)

        } catch ( error ) {
            reject(error.message)
        }
    })
}


// admin
// this is the end point to create new Admin
export const createAdmin = (formData) => {
    return new Promise(async (resolve, reject) => { 
        try {
            const accessJWT = sessionStorage.getItem("accessToken")
            
            if (!accessJWT) {
                reject("Token not found")
            }
                        
            const res = await instance.post(AUTHENTICATION.registerAdmin, formData, setToken(accessJWT))
            
            resolve(res.data)


        } catch ( error ) {
            reject(error)
        }
    })
}

export const fetchAllAdmin = (pageParam) => {
    return new Promise(async (resolve, reject) => { 
        try {
            const accessJWT = sessionStorage.getItem("accessToken")
            
            if (!accessJWT) {
                reject("Token not found")
            }
            const res = await instance.get(`${ADMIN.fetchAllAdmin}?page=${pageParam}`, setToken(accessJWT))
            
            resolve(res)

        } catch ( error ) {
            reject(error.message)
        }
    })
}



export const editAdminRole = (data) => {
    return new Promise(async (resolve, reject) => { 
        try {
            const accessJWT = sessionStorage.getItem("accessToken")
            
            if (!accessJWT) {
                reject("Token not found")
            }
                        
            const res = await instance.put(ADMIN.editAdminRole, data, setToken(accessJWT))
            resolve(res.data)

        } catch ( error ) {
            reject(error.message)
        }
    })
}


export const deleteAdminsProfile = (id) => {
    return new Promise(async (resolve, reject) => { 
        try {
            const accessJWT = sessionStorage.getItem("accessToken")
            
            if (!accessJWT) {
                reject("Token not found")
            }
                        
            const res = await instance.delete(`${ADMIN.deleteAdmin}/${id}`, setToken(accessJWT))
            resolve(res.data)

        } catch ( error ) {
            reject(error.message)
        }
    })
}




