import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { ToastContainer  } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "./index.scss";
import { db } from "../../../firebase/firebase";
import { onValue, ref } from "firebase/database";
import { addOtherInformation } from "../../../redux/Dashboard/AllUsersSlice";

const LeftPaneChatItem = ({
  open,
  setOpen,
  email,
  fullName,
  id,
  phoneNumber,
  setSelected,
  setSelectedId,
  setUserId,
  setMessage,
  status,
  setSelectedIndex,
  selectedIndex,
  index,
}) => {
  const dispatch = useDispatch();

  const handleId = (idValue) => {
    setSelectedIndex(index);
    setSelected(true);
    setUserId(id);
    setSelectedId("1");
    setOpen(() => !open);

    const dbMessage = ref(db, `messages/${id}`);

    // console.log(dbMessage);

    onValue(dbMessage, (snapshot) => {
      let data = [];
      snapshot.forEach((childSnapshot) => {
        const messageData = childSnapshot.val();

        const messageList = Object.keys(messageData).map((key) => ({
          ...messageData[key],
          uid: key,
        }));

        // toast(messageList[0].message, {
        //   position: "top-right",
        //   autoClose: 5000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: false,
        //   progress: undefined,
        //   theme: "light",
        // });

        setUserId(messageList[0].userId);
        setSelectedIndex(index);
        data?.push(messageData);
      });
 
      setMessage(data);

      const otherInfo = {
        email: email,
        fullName: fullName,
        phoneNumber: phoneNumber,
        id: idValue,
        type: status,
      };

      dispatch(addOtherInformation(otherInfo));
    });
  };

  useEffect(() => {
    handleId();

    return () => handleId();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className={
        selectedIndex === index ? "requestContentActive" : "requestContent"
      }
      onClick={() => handleId(id)}
    >
      <div className="nameContainer">
        <div className="firstLetterContainer">
          <p className="slice">{fullName?.slice(0, 1)}</p>
        </div>
        <div className="profileContainer">
          <p className="name">{fullName}</p>
          <p className="email">{email}</p>
        </div>
        <div className="time">{status === undefined ? "User" : "Driver"}</div>
      </div>

      <div className="messageHighlight"></div>

      <ToastContainer />
    </div>
  );
};

export default LeftPaneChatItem;
