import { useState } from 'react'
import {toast} from 'react-toastify'
import './index.scss'
import { editAdminRole } from '../../../api/dashboard/userApi'
import { useSelector } from 'react-redux'
import { getAdminsProfile } from '../../../redux/Dashboard/AdminAction'
import { useDispatch } from 'react-redux'

const EditUserRole = ({ editAdmin, handlePropagation }) => {
    const dispatch = useDispatch()
    const [load, setLoad] = useState(false)
    const { id } = useSelector(state => state.admin)
    const [role, setRole] = useState("SUPER")
    
    const handleRoles = (e) => {
        const selectedRoles = e.target.value
        setRole(selectedRoles)
    }

    const handleSubmit = async (e) => {
        setLoad(true)
        e.preventDefault()
        const data = {
            user_id: id,
            role: {
                role: role
            }
        }
        try {
            const resp = await editAdminRole(data)
            dispatch(getAdminsProfile())
            if (resp) {
                setTimeout(() => {
                    setLoad(false)
                    editAdmin()
                    toast.success("Admin role edited successfully")  
                }, 2000)
            }
        } catch (error) {
            setLoad(false)
            toast.error("only super Admin can change roles")
            editAdmin()
        }
    }


    return (
        <div
            className="modalContainer"
            onClick={editAdmin}
        >
            <form
                className="modalContent"
                onSubmit={handleSubmit}
                onClick={handlePropagation}
            >
                <p className='title'>Edit Admin Role</p>
                <div className="input">
                    <label htmlFor="role">Admin Role</label> 
                    <select
                        onChange={handleRoles}
                        className="select"
                        name="role"
                        id="role"
                        value={role}
                        autoComplete="off"
                        required
                    >
                        <option value="SUPER">SUPER ADMINISTRATOR</option>
                        <option value="ADMINISTRATOR">ADMINISTRATOR</option>
                    </select>

                </div>

                {load ? <div className="loading"></div> : (
                    <div className="buttonContainer">
                        <button onClick={editAdmin}>Cancel</button>
                        <button className="save">Save</button>
                    </div>
                )}
            </form>
        </div>
    )
}

export default EditUserRole