import SubCategoryLayout from "../../../layout/CategoriesLayout";
import ConfirmationPopup from "../../PopupModal";
import useRequest from "../../../helper/hooks/reviewRequest";


const AllAppSubcategory = () => {
    const {id, approve, decline, load, handleClose, handleClose2, handleApproval, handleDecline } = useRequest("/dashboard")
    
    return (
        <SubCategoryLayout
            backRouteName="dashboard"
        >
            <div className="buttonContainer">
                <button onClick={handleClose}>
                    Approve
                </button>
                <button onClick={handleClose2} className="decline">
                    Decline
                </button>
            </div>

            
            {approve && (
                <ConfirmationPopup
                    cancelFunction={handleClose}
                    load={load}
                    text="Are You sure you want to approve this driver's request?"
                    cancelButtonText="No"
                    confirmButtonText="Yes"
                    saveFunction={() => handleApproval(id)}
                />
            )}


            {decline && (
                <ConfirmationPopup
                    cancelFunction={handleClose2}
                    load={load}
                    text="Are You sure you want to decline this driver's request?"
                    cancelButtonText="No"
                    confirmButtonText="Yes"
                    saveFunction={() => handleDecline(id)}
                />
            )}
        </SubCategoryLayout>    
        
    )
}


export default AllAppSubcategory