import axios from "axios"

const baseURL =  "https://fastrack-app.herokuapp.com/api/"

export const instance = axios.create({
    baseURL,
    timeout: 300000,
    headers: {
        "Access-Control-Allow-Origin": "*",
        "content-type": "application/json",
        "X-Fastrack-key": `${process.env.REACT_APP_FASTRACK_KEY}`,
    }
})


export const updateBasePrice = (vehicleType, weight, basePrice) => {
    const endpoint = `price/base/${vehicleType}`;

    
    return new Promise(async (resolve, reject) => { 
        try {                        
            const data = {
                weight: weight,
                basePrice: basePrice      
            }
            const resp = await instance.patch(endpoint, data)
            resolve(resp)

        } catch ( error ) {
            
            reject(error)
        }
    })
}



export const getUpdatedBasePriceForBike = (weight) => {
    return new Promise(async (resolve, reject) => { 
        try {                        
            const resp = await instance.get(`price/base/bike`, {
                params: { weight }
            })
            resolve(resp)
        } catch (error) {
            reject(error)
        }
    })
}

export const getUpdatedBasePriceForCar = (weight) => {
    return new Promise(async (resolve, reject) => { 
        try {                        
            const resp = await instance.get(`price/base/car`, {
                params: { weight }
            })
            resolve(resp)
        } catch (error) {
            reject(error)
        }
    })
}

export const getUpdatedBasePrice = () => {
    return new Promise(async (resolve, reject) => { 
        try {                        
            const resp = await instance.get("price")
            resolve(resp)

        } catch ( error ) {
            
            reject(error)
        }
    })
}



