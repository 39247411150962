import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import SubCategoryLayout from "../../../layout/CategoriesLayout";
import {toast} from 'react-toastify'
import ConfirmationPopup from "../../PopupModal";
import { useDispatch } from "react-redux";
import { getAllPendingDriverApplications } from "../../../redux/Dashboard/DriverAction";
import { updateDriverApplication } from "../../../api/dashboard/applicationApi";

const PendingAppSubcategory = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const id = location.state.id
    
    const [approve, setApproval] = useState(false)
    const [decline, setDecline] = useState(false)
    const [load, setLoad] = useState(false)

    const handleApproval = async (id) => {
        setLoad(true)        
        try {
            const data = {
                verificationStatus: "ACCEPTED"
            }
            const response = await updateDriverApplication(id, data)

           

            if (response) {
                dispatch(getAllPendingDriverApplications())
                setTimeout(() => {
                    navigate("/pending-applications")
                    toast.success("driver application accepted")
                }, 1000);
            }
        } catch (error) {
            toast.error("something went wrong")
            setLoad(false)
        }
    }


    const handleDecline = async (id) => {
        setLoad(true)

        try {
            const data = {
                verificationStatus: "DECLINED"
            }
            
            const response = await updateDriverApplication(id, data)

            if (response) {
                dispatch(getAllPendingDriverApplications())
                
                setTimeout(() => {
                    navigate("/pending-applications")
                    toast.success("driver application rejected")
                }, 1000);
            }
        } catch (error) {
             if (error?.response?.status === 417) {
                dispatch(getAllPendingDriverApplications())
                
                setTimeout(() => {
                    navigate("/pending-applications")
                    toast.success(error?.response?.data?.message)
                }, 1000)
            } else {
                toast.error("something went wrong")
            }
            setLoad(false)
        }
    }

    const handleClose = () => {
        setApproval(() => !approve)
    }

    const handleClose2 =() => {
        setDecline(()=> !decline)
    }

    

    return (
        <SubCategoryLayout
            backRouteName="pending-applications"
        >
            <div className="buttonContainer">
                <button onClick={handleClose}>
                    Approve
                </button>
                <button onClick={handleClose2} className="decline">
                    Decline
                </button>
            </div>

            
            {approve && (
                <ConfirmationPopup
                    cancelFunction={handleClose}
                    load={load}
                    text="Are You sure you want to approve this driver's request?"
                    cancelButtonText="No"
                    confirmButtonText="Yes"
                    saveFunction={() => handleApproval(id)}
                />
            )}


            {decline && (
                <ConfirmationPopup
                    cancelFunction={handleClose2}
                    load={load}
                    text="Are You sure you want to decline this driver's request?"
                    cancelButtonText="No"
                    confirmButtonText="Yes"
                    saveFunction={() => handleDecline(id)}
                />
            )}
        </SubCategoryLayout>
    )
}


export default PendingAppSubcategory