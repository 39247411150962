import axios from "axios"
import { DASHBOARD } from "../apiRoutes"

const baseURL = "https://fastrack-app.herokuapp.com/api/driver"

export const instance = axios.create({
  baseURL,
  timeout: 300000,
  headers: {
    "Access-Control-Allow-Origin": "*",
    "content-type": "application/json",
    "X-Fastrack-key": `${process.env.REACT_APP_FASTRACK_KEY}`,
  },
})

export const fetchAllDriverApplications = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const resp = await instance.get("/applications")

      resolve(resp)
    } catch (error) {
      reject(error)
    }
  })
}

export const fetchSingleDriverApplications = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const resp = await instance.get(
        `${DASHBOARD.singleDriverApplication}/${id}`
      )
      resolve(resp)
    } catch (error) {
      reject(error)
    }
  })
}

export const updateDriverApplication = (id, verificationData) => {
  return new Promise(async (resolve, reject) => {
    try {
      if (!id) {
        reject("User Id Not Found")
      }

      const resp = await instance.put(`verify-driver/${id}`, verificationData)

      resolve(resp)
    } catch (error) {
      reject(error)
    }
  })
}

// Delete driver request
export const deleteDriver = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      if (!id) {
        reject("User Id Not Found")
      }

      const resp = await instance.delete(`delete/${id}`)

      resolve(resp)
    } catch (error) {
      reject(error)
    }
  })
}

export const fetchDriverApplicationsThisDay = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const resp = await instance.get("applications/?days=1")
      resolve(resp)
    } catch (error) {
      reject(error)
    }
  })
}

export const fetchDriverApplicationsThisWeek = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const resp = await instance.get("applications/?days=7")
      resolve(resp)
    } catch (error) {
      reject(error)
    }
  })
}

export const fetchDriverApplicationsThisMonth = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const resp = await instance.get("applications/?months=1")
      resolve(resp)
    } catch (error) {
      reject(error)
    }
  })
}

// fetching Approved Driver Applications
export const fetchAllApprovedDriverApplications = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const resp = await instance.get("/applications?status=accepted")
      resolve(resp)
    } catch (error) {
      reject(error)
    }
  })
}

export const fetchApprovedDriverApplicationsThisDay = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const resp = await instance.get("applications?status=accepted&days=1")
      resolve(resp)
    } catch (error) {
      reject(error)
    }
  })
}

export const fetchApprovedDriverApplicationsThisWeek = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const resp = await instance.get("applications?status=accepted&days=7")
      resolve(resp)
    } catch (error) {
      reject(error)
    }
  })
}

export const fetchApprovedDriverApplicationsThisMonth = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const resp = await instance.get("applications?status=accepted&months=1")
      resolve(resp)
    } catch (error) {
      reject(error)
    }
  })
}

// Fetching Declined Driver Applications
export const fetchAllDeclinedDriverApplications = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const resp = await instance.get("/applications?status=declined")
      resolve(resp)
    } catch (error) {
      reject(error)
    }
  })
}

export const fetchDeclinedDriverApplicationsThisDay = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const resp = await instance.get("applications?status=declined&days=1")
      resolve(resp)
    } catch (error) {
      reject(error)
    }
  })
}

export const fetchDeclinedDriverApplicationsThisWeek = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const resp = await instance.get("applications?status=declined&days=7")
      resolve(resp)
    } catch (error) {
      reject(error)
    }
  })
}

export const fetchDeclinedDriverApplicationsThisMonth = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const resp = await instance.get("applications?status=declined&months=1")
      resolve(resp)
    } catch (error) {
      reject(error)
    }
  })
}

// Fetching Pending Driver Applications
export const fetchAllPendingDriverApplications = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const resp = await instance.get("/applications?status=pending")
      resolve(resp)
    } catch (error) {
      reject(error)
    }
  })
}

export const fetchPendingDriverApplicationsThisDay = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const resp = await instance.get("applications?status=pending&days=1")
      resolve(resp)
    } catch (error) {
      reject(error)
    }
  })
}

export const fetchPendingDriverApplicationsThisWeek = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const resp = await instance.get("applications?status=pending&days=8")
      resolve(resp)
    } catch (error) {
      reject(error)
    }
  })
}

export const fetchPendingDriverApplicationsThisMonth = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const resp = await instance.get("applications?status=pending&months=1")
      resolve(resp)
    } catch (error) {
      reject(error)
    }
  })
}
