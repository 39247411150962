import PendingDeliveriesSubcategory from "../../../components/Categories/PendingDeliveries"
import DashboardLayout from "../../../layout/DashboardLayout"


const PendindDeliveriesChild = () => (
    <DashboardLayout>
        <PendingDeliveriesSubcategory/>
    </DashboardLayout>
)


export default PendindDeliveriesChild