import PendingApp from "../../../components/InnerDashboardContents/PendingAppContent"
import DashboardLayout from "../../../layout/DashboardLayout"

const PendingApplications = () => (
    <DashboardLayout>
        <PendingApp/>
    </DashboardLayout>
)


export default PendingApplications