import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { resetPassword } from "../../api/authentication/authApi";
import { toast } from "react-toastify";
import "./index.scss";
import FormInput from "../InputField/FormInput";

const PasswordReset = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const token = queryParams.get("token");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const [state, setState] = useState({
    new_password: "",
    confirm_password: "",
  });

  const submitFormData = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const resp = await resetPassword(token, state);
      setLoading(false);
      if (resp) navigate("/");
      toast.success("password reset successfully");
    } catch (error) {
      toast.error(error.response.data.message);
    }
    setLoading(false);
  };

  const handleChange = (e) => {
    const value = e.target.value;
    setState({
      ...state,
      [e.target.name]: value,
    });
  };

  const inputs = [
    {
      id: 1,
      type: "password",
      name: "new_password",
      placeholder: "*********",
      label: "New password",
      errorMessage:
        "Password should be 5-20 characters and should include 1 letter and 1 number",
      pattern: `^(?=.*[0-9])(?=.*[a-zA-Z])[a-zA-Z0-9!@#$%^&*]{5,20}$`,
      required: true,
    },

    {
      id: 2,
      type: "password",
      name: "confirm_password",
      placeholder: "*********",
      label: "Confirm Password",
      errorMessage: "Passwords don't match",
      pattern: state.new_password,
      required: true,
    },
  ];

  return (
    <>
      <form className="form" onSubmit={submitFormData}>
        {/* render input Components herre */}
        {inputs.map((input) => (
          <FormInput
            key={input.id}
            {...input}
            value={state[input.name]}
            onChange={handleChange}
          />
        ))}

        <button className="nextBtn">
          {loading ? <div className="loadingz"></div> : "Reset password"}
        </button>
      </form>
    </>
  );
};

export default PasswordReset;
