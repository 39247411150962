import ApprovedAppSubcategory from "../../../components/Categories/ApprovedApp"
import DashboardLayout from "../../../layout/DashboardLayout"

const ApprovedAppChild = () => (
    <DashboardLayout>
        <ApprovedAppSubcategory/>
    </DashboardLayout>
)


export default ApprovedAppChild