import React, { useState } from 'react'
import "./index.scss"

const KgDropDown = ({selected, setSelected, options}) => {
    const [isOpen, setIsOpen] = useState(false)
    
    const handleSelect = (e) => {
        setSelected(e)
        setIsOpen(false)
    }

    return (
        <div className="dropdown"
            onMouseEnter={() => setIsOpen(true)}
            onMouseLeave={() => {
                setIsOpen(false)
            }}
        >
            <div
                className="dropdownBtn"
            >
                {selected}
                
                {isOpen ? (
                    <div
                        onClick={() => setIsOpen(false)}
                        className="close"
                    >
                        <img src="https://res.cloudinary.com/fastrack12/image/upload/v1664369620/x_bkhxvy.svg" alt="close icon" />
                    </div>
                ) : <img src="https://res.cloudinary.com/fastrack12/image/upload/v1661256051/Vector_3_nssbya.svg" alt="down arrow icon" />
                }

            </div>

            {isOpen && (
                <div className="dropdownContent">
                    {options.map((option, index) => (
                        <div 
                            key={index}
                            onClick={() => handleSelect(option)}
                            className="dropdownItem"
                        >
                            {option} 
                        </div>
                    ))}
                </div>
            )}
        
        </div>
    )
}

export default KgDropDown