import Lottie from 'react-lottie'
import animationData from '../../animation_l8ejvn2b.json'

const ConfirmationPopup = ({
    text,
    cancelFunction,
    saveFunction,
    cancelButtonText,
    confirmButtonText,
    load
}) => {

    const defaultOptions = {
      loop: false,
      autoplay: true, 
      animationData: animationData,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    };


    const stopPropagation = (e) => { 
        e.stopPropagation()
    }

    

    return (
        <div
            onClick={cancelFunction}
            className="modalContainer"
        >
            <div
                className="modalContent"
                onClick={stopPropagation}
            >
                <div className="imageContainer">
                    <div>
                        {/* <img src="https://res.cloudinary.com/fastrack12/image/upload/v1661871096/ma1evy1xalbwmwegjthg.svg" alt="exclamation mark icon" /> */}
                        <Lottie options={defaultOptions} style={{width: "100%"}} />       
                    </div>
                    
                </div>

                <p className="popupText">{text}</p>
                
                {load ? <div className="loading"></div> : (
                    <div className="buttonContainer">
                        <button onClick={cancelFunction} className="decline">{cancelButtonText}</button>
                        <button onClick={saveFunction} className="save">{confirmButtonText}</button>             
                    </div>
                )}

            </div>

            

            
        </div>
    )
}

export default ConfirmationPopup