import ForgotPassword from '../../components/AuthForms/ForgotPassword'
import AuthLayout from '../../layout/AuthLayout'

const ForgetPassword = () => (
    <AuthLayout
        title="Forgot password"
    >
        <ForgotPassword />
    </AuthLayout>
)


export default ForgetPassword