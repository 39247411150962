import { Navigate } from 'react-router-dom'

const ProtectedRoutes = ({children}) => {
    const token = localStorage.getItem('adminToken')
    const sessionToken = sessionStorage.getItem('accessToken')

    if (!token || !sessionToken) {
        return <Navigate to="/" replace />
    }


    return (
        <>
            {children}
        </>
        
    )
}

export default ProtectedRoutes