import SubDeliveriesLayout from "../../../layout/CategoriesLayout/subDeliveries"

const CompletedDeliveriesCategory = () => {
    
    return (
        <SubDeliveriesLayout
            backRouteName="completed-deliveries"
        />
        
    )
}


export default CompletedDeliveriesCategory