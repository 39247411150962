import ApprovedApp from "../../../components/InnerDashboardContents/ApprovedAppContent"
import DashboardLayout from "../../../layout/DashboardLayout"

const ApprovedApplications = () => (
    <DashboardLayout>
        <ApprovedApp/>
    </DashboardLayout>
)


export default ApprovedApplications