import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    appUsers: [],
    messageData: [],
    otherInformation: {},
    isLoading: false,
    error: ""

}

const allUsersSlice = createSlice({
    name: "allUsers",
    initialState,
    reducers: {
        getAllAppUsersPending: (state) => {
            state.isLoading = true
        },
        
        getAllAppUsersSuccess: (state, { payload }) => {
            state.isLoading = false
            state.appUsers = payload
            state.error = ""
        },

        getAllAppUsersFail: (state, {payload}) => {
            state.isLoading = false
            state.error = payload
        },

        addMessageData: (state, { payload }) => {
            state.messageData = payload
        },

        addOtherInformation: (state, { payload }) => {
            state.otherInformation = payload
        },

    }
})

export const {
    getAllAppUsersPending,
    getAllAppUsersSuccess,
    getAllAppUsersFail,
    addMessageData,
    addOtherInformation

} = allUsersSlice.actions

export default allUsersSlice.reducer