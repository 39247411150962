import {
    fetchAllApprovedDriverApplications,
    fetchAllDeclinedDriverApplications,
    fetchAllDriverApplications, fetchAllPendingDriverApplications, fetchApprovedDriverApplicationsThisDay, fetchApprovedDriverApplicationsThisMonth, fetchApprovedDriverApplicationsThisWeek, fetchDeclinedDriverApplicationsThisDay, fetchDeclinedDriverApplicationsThisMonth, fetchDeclinedDriverApplicationsThisWeek, fetchDriverApplicationsThisDay, fetchDriverApplicationsThisMonth, fetchDriverApplicationsThisWeek, fetchPendingDriverApplicationsThisDay, fetchPendingDriverApplicationsThisMonth, fetchPendingDriverApplicationsThisWeek, fetchSingleDriverApplications
} from "../../api/dashboard/applicationApi"
import {
    getDeclinedApp,
    getApprovedApp, getApprovedDriverApplicationsThisMonth,
    getApprovedDriverApplicationsThisWeek, getApprovedDriverApplicationsToday,
    getDriverApplicationsThisMonth, getDriverApplicationsThisWeek,
    getDriverApplicationsToday, getDriverSuccess,
    getPendingApp, getSingleDriver, getDeclinedDriverApplicationsToday, getDeclinedDriverApplicationsThisWeek, getDeclinedDriverApplicationsThisMonth, getPendingDriverApplicationsThisWeek, getPendingDriverApplicationsToday, getPendingDriverApplicationsThisMonth, getDriverFail
} from "./DriverSlice"

export const getAllDriverApplications = () => async (dispatch) => {    
    try {
        const resp = await fetchAllDriverApplications()
        if (resp) {
            dispatch(getDriverSuccess(resp?.data?.drivers))
        }

    } catch(error) {
        dispatch(getDriverFail(error))
    }
}


export const getSingleApplications = () => async (dispatch) => {    
    try {
        const resp = await fetchSingleDriverApplications()
    
        
        if (resp) {
            dispatch(getSingleDriver(resp?.data))
        }

    } catch(error) {
        dispatch(getDriverFail(error))
    }
}

export const getDriverApplicationToday = () => async (dispatch) => {    
    try {
        const resp = await fetchDriverApplicationsThisDay()   
        if (resp) {
            dispatch(getDriverApplicationsToday(resp?.data?.drivers))
        }
    } catch(error) {
        dispatch(getDriverFail(error))
    }
}

export const getDriverApplicationThisWeek = () => async (dispatch) => {    
    try {
        const resp = await fetchDriverApplicationsThisWeek()   
        if (resp) {
            dispatch(getDriverApplicationsThisWeek(resp?.data?.drivers))
        }
    } catch(error) {
        dispatch(getDriverFail(error))
    }
}

export const getDriverApplicationThisMonth = () => async (dispatch) => {    
    try {
        const resp = await fetchDriverApplicationsThisMonth()   
        if (resp) {
            dispatch(getDriverApplicationsThisMonth(resp?.data?.drivers))
        }
    } catch(error) {
        dispatch(getDriverFail(error))
    }
}

// approved application
export const getAllApprovedDriverApplications = () => async (dispatch) => {    
    try {
        const resp = await fetchAllApprovedDriverApplications()
        if (resp) {
            dispatch(getApprovedApp(resp?.data?.drivers))
        }
    } catch(error) {
        dispatch(getDriverFail(error))
    }
}

export const getApprovedDriverApplicationToday = () => async (dispatch) => {    
    try {
        const resp = await fetchApprovedDriverApplicationsThisDay()   
        if (resp) {
            dispatch(getApprovedDriverApplicationsToday(resp?.data?.drivers))
        }
    } catch(error) {
        dispatch(getDriverFail(error))
    }
}

export const getApprovedDriverApplicationThisWeek = () => async (dispatch) => {    
    try {
        const resp = await fetchApprovedDriverApplicationsThisWeek()   
        if (resp) {
            dispatch(getApprovedDriverApplicationsThisWeek(resp?.data?.drivers))
        }
    } catch(error) {
        dispatch(getDriverFail(error))
    }
}

export const getApprovedDriverApplicationThisMonth = () => async (dispatch) => {    
    try {
        const resp = await fetchApprovedDriverApplicationsThisMonth()   
        if (resp) {
            dispatch(getApprovedDriverApplicationsThisMonth(resp?.data?.drivers))
        }
    } catch(error) {
        dispatch(getDriverFail(error))
    }
}




// declined Applications
export const getAllDeclinedDriverApplications = () => async (dispatch) => {    
    try {
        const resp = await fetchAllDeclinedDriverApplications()
        if (resp) {
            dispatch(getDeclinedApp(resp?.data?.drivers))
        }
    } catch(error) {
        dispatch(getDriverFail(error))
    }
}

export const getDeclinedDriverApplicationToday = () => async (dispatch) => {    
    try {
        const resp = await fetchDeclinedDriverApplicationsThisDay()   
        if (resp) {
            dispatch(getDeclinedDriverApplicationsToday(resp?.data?.drivers))
        }
    } catch(error) {
        dispatch(getDriverFail(error))
    }
}

export const getDeclinedDriverApplicationThisWeek = () => async (dispatch) => {    
    try {
        const resp = await fetchDeclinedDriverApplicationsThisWeek()   
        if (resp) {
            dispatch(getDeclinedDriverApplicationsThisWeek(resp?.data?.drivers))
        }
    } catch(error) {
        dispatch(getDriverFail(error))
    }
}

export const getDeclinedDriverApplicationThisMonth = () => async (dispatch) => {    
    try {
        const resp = await fetchDeclinedDriverApplicationsThisMonth()   
        if (resp) {
            dispatch(getDeclinedDriverApplicationsThisMonth(resp?.data?.drivers))
        }
    } catch(error) {
        dispatch(getDriverFail(error))
    }
}



// pending applications
export const getAllPendingDriverApplications = () => async (dispatch) => {    
    try {
        const resp = await fetchAllPendingDriverApplications()


        if (resp) {
            dispatch(getPendingApp(resp?.data?.drivers))
        }
    } catch(error) {
        dispatch(getDriverFail(error))
    }
}
 

export const getPendingDriverApplicationToday = () => async (dispatch) => {    
    try {
        const resp = await fetchPendingDriverApplicationsThisDay()   
        if (resp) {
            dispatch(getPendingDriverApplicationsToday(resp?.data?.drivers))
        }
    } catch(error) {
        dispatch(getDriverFail(error))
    }
}

export const getPendingDriverApplicationThisWeek = () => async (dispatch) => {    
    try {
        const resp = await fetchPendingDriverApplicationsThisWeek()   
        if (resp) {
            dispatch(getPendingDriverApplicationsThisWeek(resp?.data?.drivers))
        }
    } catch(error) {
        dispatch(getDriverFail(error))
    }
}

export const getPendingDriverApplicationThisMonth = () => async (dispatch) => {    
    try {
        const resp = await fetchPendingDriverApplicationsThisMonth()   
        if (resp) {
            dispatch(getPendingDriverApplicationsThisMonth(resp?.data?.drivers))
        }
    } catch(error) {
        dispatch(getDriverFail(error))
    }
}
