import {  useEffect, useState } from 'react'
import { DeliveriesData, DriverData, LogOutData, MoreData} from '../../utils/data'
import { NavLink, useNavigate } from "react-router-dom"
import { useLocation } from "react-router-dom"
import { useDispatch, useSelector } from 'react-redux'
import ProtectedRoutes from '../../protected'
import './index.scss'
import { getAllApprovedDriverApplications, getAllDeclinedDriverApplications, getAllDriverApplications, getAllPendingDriverApplications, getApprovedDriverApplicationThisMonth, getApprovedDriverApplicationThisWeek, getApprovedDriverApplicationToday, getDeclinedDriverApplicationThisMonth, getDeclinedDriverApplicationThisWeek, getDeclinedDriverApplicationToday, getDriverApplicationThisMonth, getDriverApplicationThisWeek, getDriverApplicationToday, getPendingDriverApplicationThisMonth, getPendingDriverApplicationThisWeek, getPendingDriverApplicationToday } from '../../redux/Dashboard/DriverAction'
import ConfirmationPopup from '../../components/PopupModal'
import { logoutDriver } from '../../redux/Dashboard/DriverSlice'
import { logoutOrderData } from '../../redux/Dashboard/OrderSlice'
import { logoutUser } from '../../redux/Dashboard/UserSlice'
import { getAllMobileUsers } from '../../redux/Dashboard/AllUsersAction'
import { addMessageData, addOtherInformation } from '../../redux/Dashboard/AllUsersSlice'
// import { getAdminsProfile } from '../../redux/Dashboard/AdminAction'


const DashboardLayout = ({ children }) => {
    const navigate = useNavigate()
    const { user } = useSelector(state => state.user)
    const dispatch = useDispatch()
    //assigning location variable
    const location = useLocation()
    //destructuring pathname from location
    const { pathname } = location
    //Javascript split method to get the name of the path in array
    const splitLocation = pathname.split("/")
    

    const [open, setOpen] = useState(false)

    const handleClick = () => {
        setOpen(()=> !open)
    }
    
    
    useEffect(() => {      
        dispatch(getAllMobileUsers())
        dispatch(getAllDriverApplications())
        dispatch(getDriverApplicationToday())
        dispatch(getDriverApplicationThisWeek())
        dispatch(getDriverApplicationThisMonth())
        
        dispatch(getAllDeclinedDriverApplications())
        dispatch(getDeclinedDriverApplicationToday())
        dispatch(getDeclinedDriverApplicationThisWeek())
        dispatch(getDeclinedDriverApplicationThisMonth())
        dispatch(getAllApprovedDriverApplications())
        dispatch(getApprovedDriverApplicationToday())
        dispatch(getApprovedDriverApplicationThisWeek())
        dispatch(getApprovedDriverApplicationThisMonth())
        dispatch(getAllPendingDriverApplications())
        dispatch(getPendingDriverApplicationToday())
        dispatch(getPendingDriverApplicationThisWeek())
        dispatch(getPendingDriverApplicationThisMonth())
        // dispatch(getUpdatedBasePrize())
        // dispatch(getAdminsProfile(1))
    }, [ dispatch])

    
    const[ logout, setLogout] = useState(false)
    const [load, setLoad] = useState(false)
    const handleClose = () => {
        setLogout(() => !logout)
    }

    const handleLogout = () => {
        setLoad(true)
        setTimeout(() => {
            localStorage.removeItem("adminToken")
            sessionStorage.removeItem("accessToken")
            dispatch(logoutDriver())
            dispatch(logoutOrderData())
            dispatch(logoutUser())
            setLoad(false)
            navigate("/")
            const otherInfo = {
                email:"",
                fullName: "",
                phoneNumber: "",
                id: ""
            }

            dispatch(addOtherInformation(otherInfo))
            dispatch(addMessageData([]))
        }, 100)
    }


    return (
        <ProtectedRoutes>
            <div className="dashboardContainer">
                <div className={`dashboardItems ${open ? "open" : ""}`}>
                    <div className="dashboardItemsTop">
                        <div className='profileIcon'>
                            <img src="https://res.cloudinary.com/fastrack12/image/upload/v1661256055/Profile_nduziq.svg" alt="admin profile icon" />
                        </div>

                        <div className="profileText">
                            <p>{user.name}</p>

                            <div className="status">
                                <p>Online</p>
                            </div>
                        
                        </div>
                    </div>

                    <div className="dashboardItemsBody">
                        <p>DRIVERS</p>
                        <ul>
                            {DriverData.map((driver) => (
                                <NavLink to={driver.link} key={driver.id} className={splitLocation[1] === "" ? "active" : "link"}>
                                    <li onClick={handleClick}>
                                        {/* <div className="img">
                                            <img src={driver.img} alt="icon" />
                                        </div> */}
                                        { driver.title }
                                    </li>
                                </NavLink>    
                            ))}
                        </ul>
                    </div>

                    <div className="dashboardItemsBody">
                        <p>DELIVERIES</p>
                        <ul>
                            {DeliveriesData.map((delivery) => (
                                <NavLink to={delivery.link} key={delivery.id} className={splitLocation[1] === "" ? "active" : "link"} >
                                    <li onClick={handleClick} key={delivery.id}>
                                        {/* <div className="img">
                                            <img src={delivery.img} alt="icon" />
                                        </div> */}
                                        { delivery.title }
                                    </li>
                                </NavLink>
                                
                            ))}
                        </ul>
                    </div>

                    <div className="dashboardItemsBody">
                        <p>MORE</p>
                        <ul>
                            {MoreData.map((more) => (
                                <NavLink to={more.link} key={more.id} className="link">
                                    <li onClick={handleClick}>
                                        {/* <div className="img">
                                            <img src={more.img} alt="icon" />
                                        </div> */}
                                        { more.title }
                                    </li>
                                </NavLink>
                                
                            ))}
                        </ul>
                    </div>

                    <div className="dashboardItemsBody">
                        <ul>
                            {LogOutData.map((more) => (
                                <li onClick={handleClose}  className="linkz" key={more.id}>
                                    {/* <div className="img">
                                        <img src={more.img} alt="icon" />
                                    </div> */}
                                    { more.title }
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
                
                {logout && (
                    <ConfirmationPopup
                        cancelFunction={handleClose}
                        load={load}
                        text="Are You sure you want to logout?"
                        cancelButtonText="No"
                        confirmButtonText="Yes"
                        saveFunction={handleLogout}
                    />
                )}


                <div className="dashboardContent">
                    <div
                        onClick={handleClick}
                        className="menuIcon">
                        {!open ? <img src="https://res.cloudinary.com/fastrack12/image/upload/v1665580761/samples/1menu_izi70k.svg" alt="open icon" /> : <img src="https://res.cloudinary.com/fastrack12/image/upload/v1665580918/samples/1_lyvhxy.svg" alt="close icon" />}    
                    </div>


                    {children}
                </div>
            </div>
        </ProtectedRoutes>
         
    )
}

export default DashboardLayout