import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { getId } from '../../../redux/Dashboard/AdminSlice'
import AdminStatus from '../../Status/adminStatus'
import './index.scss'


const AdminRow = ({ search, admin, editAdmin, handleConfirmation }) => {
    const dispatch = useDispatch()
    const [selectedId, setSelectedId] = useState(null)
    
    const handleClick = (id) => {
        if (selectedId === null) {
            setSelectedId(id)
        } else {
            setSelectedId(null)
        }
    }

    const handleEdit = () => {
        editAdmin()
        setSelectedId(null)
    }

    const handleRemove = () => {
        handleConfirmation()
        setSelectedId(null)
    }


    return (
        <>
            {admin?.data?.filter((item) => {
                return search.toLowerCase() === "" ? item : item.name.toLowerCase().includes(search.toLowerCase())
            })
            .map((item) => (
                <tr
                    key={item.id}
                    className="adminRow"
                >
                    <td className='second'>{item.name}</td>
                    <td className='second'>{item.role.role}</td>
                    <td className='second'>
                        <AdminStatus data={item.status} />
                    </td>

                    <td className='third'>
                        <button
                            onClick={() => {
                                handleClick(item.id)
                                dispatch(getId(item.id))
                            }}
                            className='status'
                        >
                            <img src="https://res.cloudinary.com/fastrack12/image/upload/v1661811866/menu_iwmnx4.svg" alt="right arrow icon" />
                        </button>

                        {selectedId === item.id && (
                            <ul className="extraCard">
                                <li onClick={handleEdit}>Edit</li>
                                <li onClick={handleRemove}>Remove</li>
                            </ul>
                        )}
                    
                    </td>            
                </tr>
            ))}
        </>
    ) 
}

export default AdminRow