import {  useState } from 'react'
import './index.scss'
import CarForm from './CarForm'
import BikeForm from './BikeForm'


const EditPrice = ({ cancelEdit, handlePropagation, setBasePrice }) => {
    const [activeTab, setActiveTab] = useState('bikes'); // Default active tab is 'bikes'
    const handleTabClick = (tab) => {
        setActiveTab(tab);
    }

    
    return (
        <div
            className="modalContainer"
            onClick={cancelEdit}
        >
            <div
                className="modalContent"
               
                onClick={handlePropagation}
            >
                <p className='title'>Edit Base Price</p>

                 {/* Tabs */}
                 <div className="tabs">
                    <div
                        className={`tab ${activeTab === 'bikes' ? 'active' : ''}`}
                        onClick={() => handleTabClick('bikes')}
                    >
                        Bikes
                    </div>
                    <div
                        className={`tab ${activeTab === 'cars' ? 'active' : ''}`}
                        onClick={() => handleTabClick('cars')}
                    >
                        Cars
                    </div>
                </div>


                <div className={`tab-content ${activeTab === 'bikes' ? 'active' : ''}`}>
                    {/* Bikes Tab Content */}
                    <BikeForm  
                        cancelEdit={cancelEdit}
                        setBasePrice={setBasePrice}
                    />
                </div>

                <div className={`tab-content ${activeTab === 'cars' ? 'active' : ''}`}>
                    {/* Cars Tab Content */}
                    <CarForm  
                        cancelEdit={cancelEdit}
                        setBasePrice={setBasePrice}
                    />
                </div>

            </div>
        </div>
    )
}

export default EditPrice