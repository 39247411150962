import './index.scss'

const Status = ({data}) => {
  return (
    <span className={data === "ACCEPTED" ? "green" : data === "PENDING" ? "yellow" : "red"}> {data} </span>
  )
}

export default Status


