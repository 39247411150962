import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    drivers: [],
    singleDriver: {},
    pendingApp: "",
    approvedApp: "",
    declinedApp: "",
    appToday: [],
    appThisWeek: [],
    appThisMonth: [],
    
    // for approved app
    approvedAppToday: [],
    approvedAppThisWeek: [],
    approvedAppThisMonth: [],
    
    // for declined app
    declinedAppToday: [],
    declinedAppThisWeek: [],
    declinedAppThisMonth: [],
    
    // for pending app
    pendingAppToday: [],
    pendingAppThisWeek: [],
    pendingAppThisMonth: [],
    
    
    isLoading: false,
    error: ""
}

const driverSlice = createSlice({
    name: "driver",
    initialState,
    reducers: {
        getDriverPending: (state) => {
            state.isLoading = true
        },
        
        getDriverSuccess: (state, { payload }) => {
            state.isLoading = false
            state.drivers = payload
            state.error = ""
        },

        getDriverFail: (state, {payload}) => {
            state.isLoading = false
            state.error = payload
        },

        getPendingApp: (state, {payload}) => {
            state.pendingApp = payload
        },

        getApprovedApp: (state, {payload}) => {
            state.approvedApp = payload
        },

        getDeclinedApp: (state, {payload}) => {
            state.declinedApp = payload
        },

        getSingleDriver: (state, { payload }) => {
            state.singleDriver = payload
        },

        getDriverApplicationsToday: (state, { payload }) => {
            state.appToday = payload
        },

        getDriverApplicationsThisWeek: (state, { payload }) => {
            state.appThisWeek = payload
        },

        getDriverApplicationsThisMonth: (state, { payload }) => {
            state.appThisMonth = payload
        },

        getApprovedDriverApplicationsToday: (state, { payload }) => {
            state.approvedAppToday = payload
        },

        getApprovedDriverApplicationsThisWeek: (state, { payload }) => {
            state.approvedAppThisWeek = payload
        },

        getApprovedDriverApplicationsThisMonth: (state, { payload }) => {
            state.approvedAppThisMonth = payload
        },

        // declined
        getDeclinedDriverApplicationsToday: (state, { payload }) => {
            state.declinedAppToday = payload
        },

        getDeclinedDriverApplicationsThisWeek: (state, { payload }) => {
            state.declinedAppThisWeek = payload
        },

        getDeclinedDriverApplicationsThisMonth: (state, { payload }) => {
            state.declinedAppThisMonth = payload
        },

        // pending
        getPendingDriverApplicationsToday: (state, { payload }) => {
            state.pendingAppToday = payload
        },

        getPendingDriverApplicationsThisWeek: (state, { payload }) => {
            state.pendingAppThisWeek = payload
        },

        getPendingDriverApplicationsThisMonth: (state, { payload }) => {
            state.pendingAppThisMonth = payload
        },

        logoutDriver: (state) => {
            state.drivers=[]
            state.singleDriver= {}
            state.pendingApp= ""
            state.approvedApp= ""
            state.declinedApp= ""
            state.appToday= []
            state.appThisWeek= []
            state.appThisMonth= []
            
            // for approved app
            state.approvedAppToday= []
            state.approvedAppThisWeek= []
            state.approvedAppThisMonth= []
            
            // for declined app
            state.declinedAppToday= []
            state.declinedAppThisWeek= []
            state.declinedAppThisMonth= []
            
            // for pending app
            state.pendingAppToday= []
            state.pendingAppThisWeek= []
            state.pendingAppThisMonth= []
        }
        

    }
})

export const {
    logoutDriver,
    getDriverPending,
    getDriverSuccess,
    getDriverFail,
    getPendingApp,
    getApprovedApp,
    getDeclinedApp,
    getSingleDriver,
    
    getDriverApplicationsToday,
    getDriverApplicationsThisWeek,
    getDriverApplicationsThisMonth,
    
    getApprovedDriverApplicationsToday,
    getApprovedDriverApplicationsThisWeek,
    getApprovedDriverApplicationsThisMonth,

    getDeclinedDriverApplicationsToday,
    getDeclinedDriverApplicationsThisWeek,
    getDeclinedDriverApplicationsThisMonth,


    getPendingDriverApplicationsToday,
    getPendingDriverApplicationsThisWeek,
    getPendingDriverApplicationsThisMonth,

} = driverSlice.actions

export default driverSlice.reducer