import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import DashboardContentLayout from '../../../layout/DashboardContentTopLayout'
import { deliveriesTableHeader } from '../../../utils/data'
import { getAllOngoingDeliveries, getAllOngoingDeliveriesThisDay, getAllOngoingDeliveriesThisMonth, getAllOngoingDeliveriesThisWeek } from '../../../redux/Dashboard/OrderAction'
import DataFilterByDate from '../../DataFilter'

const OngoingDeliveryContent = () => {
    const dispatch = useDispatch()
    const { ongoingOrder, ongoingOrderThisDay, ongoingOrderThisWeek, ongoingOrderThisMonth  } = useSelector(state => state.order)
    const [selected, setSelected] = useState("This year")


    useEffect(() => {
        dispatch(getAllOngoingDeliveries())
        dispatch(getAllOngoingDeliveriesThisDay())
        dispatch(getAllOngoingDeliveriesThisWeek())
        dispatch(getAllOngoingDeliveriesThisMonth())
    }, [dispatch])
    
    return (
        <>
            <DashboardContentLayout
                title="Ongoing Deliveries"
                selected={selected}
                setSelected={setSelected}
            />
            
            {ongoingOrder ? (
                <DataFilterByDate 
                    word="Ongoing Orders"
                    variant={true}
                    routeName="ongoing-deliveries"
                    selected={selected}
                    todayData={ongoingOrderThisDay}
                    thisWeekData={ongoingOrderThisWeek}
                    thisMonthData={ongoingOrderThisMonth}
                    thisYearData={ongoingOrder}
                    typeOfTable={deliveriesTableHeader}
                />
            ) : "loading"}  
        </>
    )
}

export default OngoingDeliveryContent