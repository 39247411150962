import { useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import SubDeliveriesLayout from "../../../layout/CategoriesLayout/subDeliveries"
import ConfirmationPopup from "../../PopupModal"
import {toast} from 'react-toastify'
import { cancelDelivery } from "../../../api/apiRoutes"

const OngoingDeliveriesCategory = () => {
    const navigate = useNavigate()
    const [cancel, setCancel] = useState(false)
    const location = useLocation()     
    const [load, setLoad] = useState(false)                           
    
    const handleCancel = () => {
        setCancel(() => !cancel)
    }

    const handleCancelRequest = async () => {
        setLoad(true)
        const orderId = location.state.id
        try {
            const resp = await cancelDelivery(orderId)
            setTimeout(() => {
                setLoad(false)
                navigate("/ongoing-deliveries")
                toast.success("Order cancelled successfully")
            }, 2000)
        } catch(error) {
            setLoad(false)
            toast.error(error.response.data.message)
        }
    }

    return (
        <>
    
            <SubDeliveriesLayout
                backRouteName="ongoing-deliveries"
            >
                
                <div className="buttonContainer">
                    <button className="decline" onClick={handleCancel}>Cancel</button>
                </div>
            </SubDeliveriesLayout>

            {cancel && (
                <ConfirmationPopup
                    cancelFunction={handleCancel}
                    saveFunction={handleCancelRequest}
                    text="Are you sure you want to cancel this delivery?"
                    cancelButtonText="No"
                    confirmButtonText="Yes, cancel"
                    load={load}
                />
            )}

        </>
        
    )
}


export default OngoingDeliveriesCategory