import CompletedDelivery from '../../../components/InnerDashboardContents/CompletedDeliveryContent'
import DashboardLayout from '../../../layout/DashboardLayout'

const CompeletedDeliveries= () =>  (
    <DashboardLayout>
        <CompletedDelivery/>
    </DashboardLayout>
)


export default CompeletedDeliveries