import axios from "axios"

const baseURL = "https://fastrack-admin-center.herokuapp.com/v1/admin"

export const instance = axios.create({
    baseURL,
    timeout: 300000,
    headers: {
        "Access-Control-Allow-Origin": "*",
        "content-type": "application/json",
    }
})


export const setToken = (token) => {
  const auth = `bearer ${token}`
  instance.defaults.headers.common['Authorization'] = auth
}



