import { getUpdatedBasePriceForBike, getUpdatedBasePriceForCar } from "../../api/dashboard/priceApi"
import { fetchAllAdmin } from "../../api/dashboard/userApi"
import { getAdminFail, getAdminPending, getAdminSuccess, getBasePriceForBike, getBasePriceForCar } from "./AdminSlice"



export const getAdminsProfile = (pageParam) => async (dispatch) => {    
    try {
        dispatch(getAdminPending())
        // call the api here
        const resp = await fetchAllAdmin(pageParam)
        return dispatch(getAdminSuccess(resp))
        
    } catch(error) {
        dispatch(getAdminFail(error))
    }
}


// export const getUpdatedBasePrize = () => async (dispatch) => {    
//     try {
//         // call the api here
//         const resp = await getUpdatedBasePrice()
//         return dispatch(getBasePrice(resp?.data?.data[0]))
//     } catch(error) {
//         dispatch(getAdminFail(error))
//     }
// }


export const getUpdatedBasePrizeForBike = (weight) => async (dispatch) => {    
    try {
        // call the api here
        const resp = await getUpdatedBasePriceForBike(weight)
        return dispatch(getBasePriceForBike(resp?.data?.data?.basePrice))

    } catch(error) {
        dispatch(getAdminFail(error))
        
    }
}


export const getUpdatedBasePrizeForCar = (weight) => async (dispatch) => {    
    try {
        // call the api here
        const resp = await getUpdatedBasePriceForCar(weight)
        return dispatch(getBasePriceForCar(resp?.data?.data?.basePrice))
        
    } catch(error) {
        dispatch(getAdminFail(error))
    }
}




