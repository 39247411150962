import { useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import ConfirmationPopup from '../../../components/PopupModal'
import {toast} from 'react-toastify'
import DashboardLayout from '../../../layout/DashboardLayout'
import DriverStatus from '../../../components/Status/driverStatus'
import { assignDriverToDelivery } from '../../../api/apiRoutes'


const DriverInformation = () => {
    const navigate = useNavigate()
    const { singleDriver } = useSelector(state => state.driver)
    const {orderId} = useSelector(state => state.order)
    const [approve, setApproval] = useState(false)
    const [load, setLoad] = useState(false)
    const [decline, setDecline] = useState(false)
    

    const handleBack = () => {
        navigate("/pending-deliveries/assign-driver")
    }

    const handleClose = () => {
        setApproval(() => !approve)
    }

    const handleClose2 =() => {
        setDecline(() => !decline)
    }
        
    const handleAssignDriver = async () => {
        setLoad(true)
        try {
            const data = {
                orderId: orderId,
                driverId: singleDriver.id
            }
            const response = await assignDriverToDelivery(data)
            if (response) {
                setLoad(false)
                navigate("/pending-deliveries")
                toast.success("driver assigned successfully")         
            }
            
        } catch (error) {
            setApproval(false)
            setLoad(false)
            navigate("/pending-deliveries/assign-driver")
            toast.error(error.response.data.message)
        }
    }

    const handleDecline = async () => {
        navigate("/pending-deliveries/assign-driver")
    }


    return (
        <DashboardLayout>          
            <div className="subCategoriesContainer">
                <div className="topContainer">
                    <div
                        onClick={handleBack}
                        className="goBackBtn"
                    >
                    <img src="https://res.cloudinary.com/fastrack12/image/upload/v1661459504/Vector_5_htgbvd.svg" alt="left arrow icon" />
                    <p>Go back</p>
                    </div>

                    <div className="profileContainer">
                        <div className="profileImageContainer">
                            <img src={singleDriver?.userPassportImage} alt="user passport" />
                        </div>

                        <div className="profileDescription">
                            <div className="nameContainer">
                                <p className="name">{singleDriver?.fullName}</p>
                                {/* passing the status component */}
                                <DriverStatus data={singleDriver?.onlineStatus} />
                            </div>
                            <p className="registrationNumber">
                                Registration number: <span> { singleDriver?.id }</span>
                            </p>
                        </div>
                    </div>
                </div>

                {/* body content */}
                <div className="bottomContainer">
                    <div className="items">
                        <p className="title">Driver's Name</p>
                        <p className="name">{ singleDriver?.fullName}</p>
                    </div>

                    <div className="items">
                        <p className="title">Phone Number</p>
                        <p className="name">{ singleDriver?.phoneNumber || "NA"}</p>
                    </div>
                </div>

                <div className="bottomContainer">
                    <div className="items">
                        <p className="title">Email Address</p>
                        <p className="name">{ singleDriver?.email || "NA"}</p>
                    </div>

                    <div className="items">
                        <p className="title">Country</p>
                        <p className="name">{ singleDriver?.nationality || "NA"}</p>
                    </div>
                </div>

                <div className="bottomContainer">
                    <div className="items">
                        <p className="title">City</p>
                        <p className="name">{singleDriver?.city || "NA"} </p>
                    </div>

                    <div className="items">
                        <p className="title">Date of birth </p>
                        <p className="name">{singleDriver?.dateOfBirth || "NA"}</p>
                    </div>
                </div>


                <div className="bottomContainer">
                    <div className="items">
                        <p className="title">Liscence number</p>
                        <p className="name">{singleDriver?.liscenceNumber || "NA"}</p>
                    </div>

                    <div className="items">
                        <p className="title">About</p>
                        <p className="name">{singleDriver?.about || "NA"}</p>
                    </div>
                </div>

                
                {/* the children i.e ReactNode elements goes in here if any */}
                <div className="buttonContainer">
                    <button
                        onClick={ handleClose}
                    >
                        Assign
                    </button>
                    <button
                        onClick={handleClose2}
                        className="decline">
                        Decline
                    </button>
                </div>


                {approve && (
                    <ConfirmationPopup
                        cancelFunction={handleClose}
                        load={load}
                        text="Are You sure you want to assign this driver to this delivery?"
                        cancelButtonText="No"
                        confirmButtonText="Yes"
                        saveFunction={handleAssignDriver}
                    />
                )}


                {decline && (
                    <ConfirmationPopup
                        cancelFunction={handleClose2}
                        text="Are You sure you want to decline this driver?"
                        cancelButtonText="No"
                        confirmButtonText="Yes"
                        saveFunction={handleDecline}
                    />
                )}

            </div>

        
        </DashboardLayout>
  )
}

export default DriverInformation