import AdminPassword from '../../components/AuthForms/AdminPassword'
import AuthLayout from '../../layout/AuthLayout'

const UserPassword = () => (
    <AuthLayout
        title="Create password"
    >
        <AdminPassword />
    </AuthLayout>
)


export default UserPassword