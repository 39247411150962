import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateBasePrice} from '../../../api/dashboard/priceApi'

import {toast} from 'react-toastify'
import {getUpdatedBasePrizeForCar } from '../../../redux/Dashboard/AdminAction'
import KgDropDown from '../../Dropdown/kg'


const CarForm = ({cancelEdit, setBasePrice}) => {
    const {  basePriceForCar } = useSelector(state => state.admin) 
    const dispatch = useDispatch()
    const [load, setLoad] = useState(false)


    const [selected, setSelected] = useState("20kg")
    const options = ["20kg", "50kg", "100kg", "200kg"]



    const [state, setState] = useState({
        base_price: "",
        confrmBasePrice: "",
    })


    const handleSubmit = async (e) => {
        setLoad(true)
        e.preventDefault()
    

        const stateValue = state.base_price

        if(state.base_price !== state.confrmBasePrice) {
            setLoad(false)
            toast.error("Base Price and the Confirmation price are not the same ")
        } else {
            try {
                const resp = await updateBasePrice("car", selected, stateValue)
                setLoad(false)        
                toast.success(resp?.data?.message)
                if (resp) {
                    setBasePrice(false)
                }
            } catch (error) {
                setLoad(false)
                toast.error(error.response.data.message)
            }
        }
    }


    const handleChange = (e) => {
        const value = e.target.value
        setState({
            ...state,
            [e.target.name]: value
        })
    }


    useEffect(() => {
        dispatch(getUpdatedBasePrizeForCar(selected))
        
    }, [dispatch, selected])


    return (

        <>
            <div className='kgDropDownContainer'>
                <p>Select Weight: </p>
                <KgDropDown options={options} selected={selected} setSelected={setSelected} />
            </div>
       
            <form onSubmit={handleSubmit}>
                <div className="input">
                <label htmlFor="name">Current Base Price For {selected} Cars</label>
                <input
                    type="text"
                    placeholder="name"
                    value={`₺ ${basePriceForCar || "--"} `}
                />
                </div>

                <div className="input">
                <label htmlFor="name">New Base Price For {selected} Cars</label>
                <input
                    onChange={handleChange}
                    id="base_price"
                    name="base_price"
                    type="text"
                    placeholder="₺ 18,70"
                    value={state.base_price}
                    required
                />
                </div>

                <div className="input">
                <label htmlFor="confrmBasePrice">Confirm Base Price For {selected} Cars</label>
                <input
                    onChange={handleChange}
                    id="confrmBasePrice"
                    name="confrmBasePrice"
                    type="confrmBasePrice"
                    placeholder="₺ 18,70"
                    value={state.confrmBasePrice}
                    required
                />
                </div>

                {load ? (
                    <div className="loading"></div>
                    ) : (
                    <div className="buttonContainer">
                        <button onClick={cancelEdit}>Cancel</button>
                        <button className="save">Update</button>
                    </div>
                )}
            </form>
        </>
    )
}

export default CarForm