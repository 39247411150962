import { useSelector } from 'react-redux'
import './index.scss'
import DropDown from '../../components/Dropdown'
import Notifications from '../../components/Notifications/Notifications'


const DashboardContentLayout = ({ title, selected, setSelected }) => {
    const { drivers, pendingApp, approvedApp, declinedApp } = useSelector(state => state.driver)


    
    return (
        <div className="topContainer">
        
            <div className="titleContainer">                 
                <p>{title}</p>

                <div
                    className="rightContainer"
                >
                    <Notifications />
                    <DropDown selected={selected} setSelected={setSelected} />
                </div>
                
            </div>
            
            <div className="contentContainer">
                <div className="card">
                    <p className='cardTitle'>Total Applications</p>
                    <p className='cardValue'>{drivers.length}</p>
                </div>
                
                <div className="card">
                    <p className='cardTitle'>Pending Applications</p>
                    <p className='cardValue'>{pendingApp.length}</p>
                </div>

                <div className="card">
                    <p className='cardTitle'>Approved Applications</p>
                    <p className='cardValue'>{approvedApp.length}</p>
                </div>

                <div className="card last">
                    <p className='cardTitle'>Rejected Applications</p>
                    <p className='cardValue'>{declinedApp.length}</p>
                </div>
            </div>

        </div>
    )
}

export default DashboardContentLayout