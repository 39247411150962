import DeclinedApp from "../../../components/InnerDashboardContents/DeclinedAppContent"
import DashboardLayout from "../../../layout/DashboardLayout"

const DeclinedApplications = () => (
    <DashboardLayout>
        <DeclinedApp/>
    </DashboardLayout>
)


export default DeclinedApplications