import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getAdminsProfile,  getUpdatedBasePrizeForBike, getUpdatedBasePrizeForCar } from "../../../redux/Dashboard/AdminAction"
import { deleteAdminsProfile } from "../../../api/dashboard/userApi"
import ConfirmationPopup from "../../PopupModal"
import AddUser from "./AddUser"
import AdminTable from "./AdminTable"
import EditUserRole from "./EditUserRole"
import { toast } from "react-toastify"
import "./index.scss"
import EditPrice from "./EditPrice"


const UsersContent = () => {
  const dispatch = useDispatch()
  const [page, setPage] = useState(1)
  const { admin, id } = useSelector((state) => state.admin)
  const [user, setUser] = useState(false)
  const [confirm, setConfirm] = useState(false)
  const [edit, setEdit] = useState(false)
  const [load, setLoad] = useState(false)
  const [basePrice, setBasePrice] = useState(false)

  const editBasePrice = () => {
    setBasePrice(() => !basePrice)
  }

  const addAdmin = () => {
    setUser(() => !user)
  }


  const editAdmin = () => {
    setEdit(() => !edit)
  }

  const handleConfirmation = () => {
    setConfirm(() => !confirm)
  }

  const handlePropagation = (e) => {
    e.stopPropagation()
  }

  useEffect(() => {
    dispatch(getAdminsProfile(page))
    dispatch(getUpdatedBasePrizeForBike())
    dispatch(getUpdatedBasePrizeForCar())
  }, [dispatch, page])




  const nextPage = () => {
    setPage((prev) => prev + 1)
  }
  const prevPage = () => {
    setPage((prev) => prev - 1)
  }

  // to delete the AdminsProfile from Dashboard
  const handleDelete = async () => {
    setLoad(true)
    try {
      const resp = await deleteAdminsProfile(id)
      dispatch(getAdminsProfile(page))

      if (resp) {
        setTimeout(() => {
          setLoad(false)
          handleConfirmation()
          toast.success(resp?.message)
        }, 2000)
      }
    } catch (error) {
      handleConfirmation()
      setLoad(false)
      toast.error("unable to delete admin, try again")
    }
  }

  return (
    <>
      <div className="topContainer adminSection">
        <div className="titleContainer">
          <p>Admin</p>

          <div className="adminBtn">
            <div onClick={editBasePrice} className="userBtn">
              Edit Base Price
            </div>
            <div onClick={addAdmin} className="addBtn">
              Add New Users
            </div>
          </div>
        </div>
      </div>

      {admin?.data?.length !== 0 ? (
        <AdminTable
          admin={admin?.data}
          editAdmin={editAdmin}
          handleConfirmation={handleConfirmation}
          nextPage={nextPage}
          prevPage={prevPage}
          page={page}
        />
      ) : (
        "loading"
      )}

      {/* anytime the add new user button is toggle to be 
                true, show the addUser form component
                otherwise, hide it
            */}
      {user && (
        <AddUser cancelAdmin={addAdmin} handlePropagation={handlePropagation} />
      )}

      {edit && (
        <EditUserRole
          editAdmin={editAdmin}
          handlePropagation={handlePropagation}
        />
      )}

      {confirm && (
        <ConfirmationPopup
          load={load}
          cancelFunction={handleConfirmation}
          saveFunction={handleDelete}
          text="Are you sure you want to remove this admin?"
          cancelButtonText="Cancel"
          confirmButtonText="Yes, remove"
        />
      )}

      {basePrice && (
        <EditPrice
          cancelEdit={editBasePrice}
          handlePropagation={handlePropagation}
          setBasePrice={setBasePrice}
        />
      )}
    </>
  )
}

export default UsersContent
