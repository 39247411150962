import {  createSlice } from "@reduxjs/toolkit"

const initialState = {
    admin: [],
    basePriceForBike: "",
    basePriceForCar: "",
    id: "",
    isLoading: false,
    error: "",
    token: ""
}



const AdminSlice = createSlice({
    name: "admin",
    initialState,
    reducers: {
        getAdminPending: (state) => {
            state.isLoading = true
        },
        
        getAdminSuccess: (state, { payload }) => {
            state.isLoading = false
            state.admin = payload
            state.error = ""
        },

        getId: (state, { payload }) => {
            state.id = payload
        },

        getBasePriceForBike: (state, { payload }) => {
           state.basePriceForBike = payload
        },

        getBasePriceForCar: (state, { payload }) => {
            state.basePriceForCar = payload
        },

        getAdminFail: (state, {payload}) => {
            state.isLoading = false
            state.error = payload
        },  
    },

    
})

export const {
    getAdminPending,
    getAdminSuccess,
    getAdminFail,
    getId,
    getBasePriceForBike,
    getBasePriceForCar
} = AdminSlice.actions

export default AdminSlice.reducer