import { useState } from 'react'

const FormInput = (props) => {
    const { label, errorMessage, onChange, id, type, ...inputProps} = props
    const [focused, setFocused] = useState(false)
    const [open, setOpen] = useState(false)
    const handleFocus = () => {
        setFocused(true)
    }

    const handleClick = () => {
        // alert("you click me")
        setOpen(() => !open)
    }

    return (
        <div className="input">
            <label htmlFor="number">{label}</label> 
            
            {type === "password" ? (
                <>
                    <input
                        id={id}
                        {...inputProps}
                        onChange={onChange}
                        type={open ? "text" : "password"}
                        onBlur={handleFocus}
                        onFocus={() => inputProps.name==="password_confirmation" && setFocused(true)}
                        focused={focused.toString()}
                    />
                    <div
                        onClick={handleClick}
                        className="eye"
                    >
                        {!open ? <img src="https://res.cloudinary.com/fastrack12/image/upload/v1664517611/Group_3_ghf88d.svg" alt="show eye icon" /> : <img src="https://res.cloudinary.com/fastrack12/image/upload/v1664517611/remove_red_eye_fthsxv.svg" alt="remove eye icon" />} 
                    </div>
                </>
            ) : (
                <input
                    id={id}
                    {...inputProps}
                    onChange={onChange}
                    onBlur={handleFocus}
                    onFocus={() => inputProps.name==="password_confirmation" && setFocused(true)}
                    focused={focused.toString()}
                />
            )}
            
            <span className="errorMessage">{errorMessage}</span>
        </div>
    )
}

export default FormInput