import { fetchAllMobileUsersApplications } from "../../api/dashboard/mobileAppUserApi";
import { getAllAppUsersFail, getAllAppUsersSuccess } from "./AllUsersSlice";
// import { getDriverSuccess } from "./DriverSlice"

export const getAllMobileUsers = () => async (dispatch) => {
  try {
    const resp = await fetchAllMobileUsersApplications();

    if (resp) {
      dispatch(getAllAppUsersSuccess(resp?.data?.data));
    }
  } catch (error) {
    dispatch(getAllAppUsersFail(error));
  }
};
