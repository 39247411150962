import OngoingDelivery from '../../../components/InnerDashboardContents/OngoingDeliveryContent'
import DashboardLayout from '../../../layout/DashboardLayout'

const OngoingDeliveries = () => (
    <DashboardLayout>
        <OngoingDelivery/>
    </DashboardLayout>
)


export default OngoingDeliveries