import './index.scss'
import { useNavigate } from "react-router-dom"
import { useState } from 'react'
import Status from '../Status'
import DeliveryStatus from '../Status/deliveryStatus'

const DataRow = ({rowData, routeName, variant }) => {
    let navigate = useNavigate()

    const [idState] = useState({
        id: rowData.id,
        fullName: rowData.fullName,
        email: rowData.email,
        phoneNumber: rowData.phoneNumber,
        city: rowData.city,
        nationality: rowData.nationality,
        status: rowData.onlineStatus,
        verificationStatus: rowData.verificationStatus,
        dateOfBirth: rowData.dateOfBirth,
        userImage: rowData.userImage,
        userPassportImage: rowData.userPassportImage,
        about: rowData.about,
        orderStatus: rowData.orderStatus,
        senderFullName: rowData.senderFullName,
        senderPhone: rowData.senderPhone,
        senderAddressDetails: rowData.senderAddressDetails,
        liscenseNumber: rowData.liscenseNumber,
        description: rowData.description,
        pickupLocation: rowData.pickupLocation,
        packageCost: rowData.packageCost,
        packageValue: rowData.packageValue,
        recipientFullName: rowData.recipientFullName,
        nationalIdPassportNumber: rowData.nationalIdPassportNumber,
        recipientPhoneNumber: rowData.recipientPhoneNumber,
        destinationLocation: rowData.destinationLocation,
        recipientAddressDetails: rowData.recipientAddressDetails,
        paymentMethod: rowData.paymentMethod
    })

    const handleRoutesId = (e) => {
        const id = e.currentTarget.id
        navigate("/"+routeName+"/"+id, {
            state: idState
        })
    }



    

    return (
        <>
            <tr
                id={rowData.id}
                onClick={handleRoutesId}
            >
                {variant ? (
                    <>
                        <td className='second'>{rowData.senderFullName}</td>
                        <td className='second'>{rowData.senderPhone}</td>
                        <td className='second'>{rowData.senderAddressDetails}</td>
                        <td className='second'>{rowData.description}</td>
                        <td className='third'>
                            {/* passing the status component here */}
                            <DeliveryStatus data={rowData.orderStatus} />
                        </td>
                        <td className='third'>
                            <button className='status'>
                                <img src="https://res.cloudinary.com/fastrack12/image/upload/v1661338865/Vector_4_tdmix9.svg" alt="right arrow icon" />
                            </button>
                        </td>
                    </>
                ) : (
                        <>             
                            <td>{rowData.fullName}</td>
                            <td>{rowData.email}</td>
                            <td>{rowData.phoneNumber}</td>
                            <td>{rowData.nationality}</td>
                            <td >
                                {/* passing the status component here */}
                                <Status data={rowData.verificationStatus || "PENDING"} />
                            </td>
                            <td>
                                <button className='status'>
                                    <img src="https://res.cloudinary.com/fastrack12/image/upload/v1661338865/Vector_4_tdmix9.svg" alt="right arrow icon" />
                                </button>
                            </td>
                        </>
                    ) 
                }
            </tr>
        </>
    ) 
}

export default DataRow