import React from 'react'
import DataTable from '../DataTable'
import EmptyTray from '../EmptyTray'

const DataFilterByDate = ({word, typeOfTable, variant, routeName, selected, todayData, thisWeekData, thisMonthData, thisYearData}) => {
    const Data = (appDateData) => {
        return (
            <>
               { appDateData?.length !== 0 ? (
                    <DataTable
                        APIData={appDateData}
                        routeName={routeName}
                        headerName={typeOfTable}
                        variant={variant}
                    /> ) : <EmptyTray text={"No " +word+ " yet for "+selected+", new " +word+" will appear here."} />
                }
            </>
            
    )}

    
    const dataTable = (selected) => {
        return (
            <React.Fragment>
                {selected === "Today"  &&  Data(todayData)}
                {selected === "This week"  && Data(thisWeekData)}
                {selected === "This month"  && Data(thisMonthData)}
                {selected === "This year"  && Data(thisYearData)}
            </React.Fragment>     
        )   
    }

    return (
        <>
            {dataTable(selected)}
        </>
    )
}

export default DataFilterByDate