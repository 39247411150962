import './index.scss'

const AuthLayout = ({ title, description, children }) => (
    <div className='authLayoutContainer'>
        <div className="adminLogo" >
            <img src="https://res.cloudinary.com/fastrack12/image/upload/v1661239866/adminLogo_by76ot.svg" alt="fastrack logo icon" />
        </div>
        <div className='childrenContainer'>
            <div className='childrenHeader'>
                <p className="title">{ title }</p>
                <p className='description'>{ description }</p>
            </div>
            <div className='childrenBody'>
                { children }
            </div>
        </div>
    </div>
)


export default AuthLayout