import { instance } from '../apiClient'
import { AUTHENTICATION } from '../apiRoutes'

// endpoint for login
export const userLogin = (formData) => {
    return new Promise(async (resolve, reject) => { 
        try {
            const res = await instance.post(AUTHENTICATION.login, formData)
            resolve(res.data)
            if (res.data.success === true) {
                localStorage.setItem('adminToken', JSON.stringify(res.data.data.token))
                sessionStorage.setItem("accessToken", res.data.data.token)
            }
        } catch ( error ) {
            reject(error)
        }
    })
}



// this is the end point to verify new admin account
// it should be noted that bearer token received from the email is passed along the 
// url of the call
export const verifyUserAccount = (accessToken, formData ) => {
    return new Promise(async (resolve, reject) => { 
        try {
            
            if (!accessToken && !formData) {
                reject("Token and data not found")
            }
                        
            const res = await instance.post(`${AUTHENTICATION.verifyAccount}/${accessToken}`, formData)
            
            resolve(res.data)

        } catch ( error ) {
            reject(error)
        }
    })
}


// nn n
// this is the end point for receiving email address of users when they forget their password
export const forgotPassword = (formData) => {
    return new Promise(async (resolve, reject) => { 
        try {
            const res = await instance.post(AUTHENTICATION.forgotPassword, formData)
            resolve(res)

        } catch ( error ) {
            reject(error)
        }
    })
}


// this is the end point for Resetting Password of users when they forget their password
export const resetPassword = (id, formData) => {
    return new Promise(async (resolve, reject) => { 
        try {
            const res = await instance.put(`${AUTHENTICATION.ResetPassword}/${id}`, formData)
            resolve(res)

        } catch ( error ) {
            reject(error)
        }
    })
}
