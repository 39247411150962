import { useEffect, useState } from "react";
import { useLocation,  useNavigate } from "react-router-dom";
import DriverStatus from "../../components/Status/driverStatus";
import './index.scss'

const SubCategoryLayout = ({backRouteName, children}) => {
    const location  = useLocation()
    const navigate = useNavigate()


    const handleBack = () => {
        navigate("/"+backRouteName)
    }
    
    let dob = location.state.dateOfBirth
    let [dobs, setDobs] = useState(dob) 

    useEffect(() => {
        if (dobs === null) {
            setDobs(location.state.dateOfBirth)
        } else {
            setDobs(location.state.dateOfBirth.slice(0, 10))
        }
    }, [location.state.dateOfBirth, dobs])
    

    
    const [close, setClose] = useState(false)
    const [imageSrc, setImageSrc] = useState("")
    const handleClick = (imageSrc) => {
        setImageSrc(imageSrc)
        setClose(true)
    }

    const handleClose = () => {
        setClose(false)
    }

    const imageModal = (imageSrc) => {
        return (
            <div className="transparentContainer" onClick={handleClose}>
                <div className="passportContainer">
                    <img src={imageSrc} alt="images of the driver" />
                </div>
            </div>
        )
    }

    return (
        <div className="subCategoriesContainer">      
            {close ? (    
                imageModal(imageSrc) 
            ) : null}
            

            <div className="topContainer">
                <div
                    onClick={handleBack}
                    className="goBackBtn"
                >
                    <img src="https://res.cloudinary.com/fastrack12/image/upload/v1661459504/Vector_5_htgbvd.svg" alt="left arrow icon" />
                    <p>Go back</p>
                </div>


                <div className="profileContainer">
                    <div className="profileImageContainer" onClick={()=> handleClick(location.state.userImage)}>
                        <img src={location.state.userImage} alt="user passport" />
                    </div>

                    <div className="profileDescription">
                        <div className="nameContainer">
                            <p className="name">{location.state.fullName}</p>
                            {/* passing the status component */}
                            <DriverStatus data={location.state.status} />
                        </div>
                        <p className="registrationNumber">
                            Registration number: <span> { location.state.id }</span>
                        </p>
                    </div>
                </div>
            </div>

            {/* body content */}
            <div className="bottomContainer">
                <div className="items">
                    <p className="title">Driver's Name</p>
                    <p className="name">{ location.state.fullName}</p>
                </div>

                <div className="items">
                    <p className="title">Phone Number</p>
                    <p className="name">{ location.state.phoneNumber || "NA"}</p>
                </div>
            </div>

            <div className="bottomContainer">
                <div className="items">
                    <p className="title">Email Address</p>
                    <p className="name">{ location.state.email || "NA"}</p>
                </div>

                <div className="items">
                    <p className="title">Country</p>
                    <p className="name">{ location.state.nationality || "NA"}</p>
                </div>
            </div>

            <div className="bottomContainer">
                <div className="items">
                    <p className="title">City</p>
                    <p className="name">{location.state.city || "NA"} </p>
                </div>

                <div className="items">
                    <p className="title">Date of birth </p>
                    <p className="name">{dobs || "NA"}</p>
                </div>
            </div>


            <div className="bottomContainer">
                <div className="items">
                    <p className="title">Liscence number</p>
                    <p className="name">{location.state.liscenseNumber || "NA"}</p>
                </div>

                <div className="items">
                    <p className="title">About</p>
                    <p className="name">{location.state.about || "NA"}</p>
                </div>
            </div>

            <div className="bottomContainer lastImage">
                <div className="items">
                    <p className="title">National ID Number</p>
                    <p className="name">{location.state.nationalIdPassportNumber || "NA"}</p>
                </div>

                <div className="items">
                    <p className="title">National Id</p>
                    <div className="passportImage more" onClick={()=> handleClick(location.state.userPassportImage)}>
                         <img src={location.state.userPassportImage} alt="user passport" />
                    </div>
                </div>
            </div>
            {/* the children i.e ReactNode elements goes in here if any */}
            {children}
            
        </div>
    )
}


export default SubCategoryLayout