import "./index.scss"

const EmptyTray = ({text}) => {
    return (
        <div className="emptyFileContainer">
            <div className="tryImage">
                <img src="https://res.cloudinary.com/fastrack12/image/upload/v1665862405/emptyFile_ukbjwo.svg" alt="empty file icon" />
            </div>

            <p>{text}</p>
        </div>
    )
}

export default EmptyTray