import Support from '../../../components/InnerDashboardContents/SupportContent'
import DashboardLayout from '../../../layout/DashboardLayout'

const SupportRequestPage = () => (
    <DashboardLayout>
        <Support/>
    </DashboardLayout>
)


export default SupportRequestPage