import { getOngoingDeliveries, getCompletedDeliveries, getPendingDeliveries, getPendingDeliveriesThisDay, getPendingDeliveriesThisWeek, getPendingDeliveriesThisMonth, getCompletedDeliveriesThisDay, getCompletedDeliveriesThisWeek, getCompletedDeliveriesThisMonth, getOngoingDeliveriesThisMonth, getOngoingDeliveriesThisWeek, getOngoingDeliveriesThisDay, checkAllAvailableDriverToADelivery } from "../../api/dashboard/deliveriesApi"
import {  getOngoingOrderSuccess, getCompletedOrderSuccess, getPendingOrderSuccess, getPendingOrderSuccessThisDay, getPendingOrderSuccessThisWeek, getPendingOrderSuccessThisMonth, getCompletedOrderSuccessThisDay, getCompletedOrderSuccessThisWeek, getCompletedOrderSuccessThisMonth, getOngoingOrderSuccessThisMonth, getOngoingOrderSuccessThisWeek, getOngoingOrderSuccessThisDay, getOrderFail, setAvailableDriver } from "./OrderSlice"




export const getAllPendingDeliveries = () => async (dispatch) => {    
    try {
        const resp = await getPendingDeliveries()

        if (resp) {
            dispatch(getPendingOrderSuccess(resp?.data?.data))
        }

    } catch(error) {
        dispatch(getOrderFail(error))
    }

}


export const getAllAvailableDriverToDeliveries = (orderId) => async (dispatch) => {    
    try {
        const resp = await checkAllAvailableDriverToADelivery(orderId)
       
        if (resp) {
            dispatch(setAvailableDriver(resp?.data?.data))
        }

    } catch (error) {
        dispatch(getOrderFail(error))
    }

}


// 1
export const getAllPendingDeliveriesThisDay = () => async (dispatch) => {    
    try {
        const resp = await getPendingDeliveriesThisDay()
        
                
        if (resp) {
            dispatch(getPendingOrderSuccessThisDay(resp?.data?.data))
        }
    } catch(error) {
        dispatch(getOrderFail(error))
    }

}

export const getAllPendingDeliveriesThisWeek = () => async (dispatch) => {    
    try {
        const resp = await getPendingDeliveriesThisWeek()
        
                
        if (resp) {
            dispatch(getPendingOrderSuccessThisWeek(resp?.data?.data))
        }
    } catch(error) {
        dispatch(getOrderFail(error))
    }
}

export const getAllPendingDeliveriesThisMonth = () => async (dispatch) => {    
    try {
        const resp = await getPendingDeliveriesThisMonth()
        
                
        if (resp) {
            dispatch(getPendingOrderSuccessThisMonth(resp?.data?.data))
        }
    } catch(error) {
        dispatch(getOrderFail(error))
    }
}




export const getAllOngoingDeliveries = () => async (dispatch) => {    
    try {
        const resp = await getOngoingDeliveries()
        if (resp) {
            dispatch(getOngoingOrderSuccess(resp?.data?.data))
        }

    } catch(error) {
        dispatch(getOrderFail(error))
    }

}

export const getAllOngoingDeliveriesThisDay = () => async (dispatch) => {    
    try {
        const resp = await getOngoingDeliveriesThisDay()
                
        if (resp) {
            dispatch(getOngoingOrderSuccessThisDay(resp?.data?.data))
        }
    } catch(error) {
        dispatch(getOrderFail(error))
    }

}

export const getAllOngoingDeliveriesThisWeek = () => async (dispatch) => {    
    try {
        const resp = await getOngoingDeliveriesThisWeek()
        
                
        if (resp) {
            dispatch(getOngoingOrderSuccessThisWeek(resp?.data?.data))
        }
    } catch(error) {
        dispatch(getOrderFail(error))
    }
}

export const getAllOngoingDeliveriesThisMonth = () => async (dispatch) => {    
    try {
        const resp = await getOngoingDeliveriesThisMonth()
        
                
        if (resp) {
            dispatch(getOngoingOrderSuccessThisMonth(resp?.data?.data))
        }
    } catch(error) {
        dispatch(getOrderFail(error))
    }
}



export const getAllCompletedDeliveries = () => async (dispatch) => {    
    try {
        const resp = await getCompletedDeliveries()
                
        if (resp) {
            dispatch(getCompletedOrderSuccess(resp?.data?.data))
        }

    } catch(error) {
        dispatch(getOrderFail(error))
    }

}
export const getAllCompletedDeliveriesThisDay = () => async (dispatch) => {    
    try {
        const resp = await getCompletedDeliveriesThisDay()
                
        if (resp) {
            dispatch(getCompletedOrderSuccessThisDay(resp?.data?.data))
        }
    } catch(error) {
        dispatch(getOrderFail(error))
    }

}

export const getAllCompletedDeliveriesThisWeek = () => async (dispatch) => {    
    try {
        const resp = await getCompletedDeliveriesThisWeek()
        
                
        if (resp) {
            dispatch(getCompletedOrderSuccessThisWeek(resp?.data?.data))
        }
    } catch(error) {
        dispatch(getOrderFail(error))
    }
}

export const getAllCompletedDeliveriesThisMonth = () => async (dispatch) => {    
    try {
        const resp = await getCompletedDeliveriesThisMonth()
       
                
        if (resp) {
            dispatch(getCompletedOrderSuccessThisMonth(resp?.data?.data))
        }
    } catch(error) {
        dispatch(getOrderFail(error))
    }
}


