import "./index.scss";

const Pagination = ({ currentPage, handleNext, handlePrevious, admin }) => {
  return (
    <div className="paginationContainer">
      <button>{currentPage}</button>{" "}
      <span>of &nbsp;{admin?.pagination?.totalPages}</span>
      <button
        className={`leftArrow ${currentPage === "1" ? "disabled" : ""}`}
        onClick={handlePrevious}
        disabled={admin?.pagination?.hasPreviousPage === false}
      >
        <img
          src="https://res.cloudinary.com/fastrack12/image/upload/v1661338865/Vector_4_tdmix9.svg"
          alt="left arrow icon"
        />
      </button>
      <button
        className={`rightArrow ${
          currentPage === admin?.pagination?.totalPages ? "disabled" : ""
        }`}
        onClick={handleNext}
        disabled={admin?.pagination?.hasNextPage === false}
      >
        <img
          src="https://res.cloudinary.com/fastrack12/image/upload/v1661338865/Vector_4_tdmix9.svg"
          alt="right arrow icon"
        />
      </button>
    </div>
  );
};

export default Pagination;
