import "./index.scss";
import { useNavigate } from "react-router-dom";
import Status from "../../Status";
import { getSingleDriver } from "../../../redux/Dashboard/DriverSlice";
import { useDispatch } from "react-redux";
import { fetchSingleDriverApplications } from "../../../api/dashboard/applicationApi";

const DataRow = ({ rowData, routeName, variant }) => {
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const handleRoutesId = async (e) => {
    const id = e.currentTarget.id;
    try {
      const resp = await fetchSingleDriverApplications(id);
      dispatch(getSingleDriver(resp?.data));
      if (resp) navigate("/pending-deliveries/" + routeName);
    } catch (error) {}
  };

  return (
    <>
      <tr id={rowData?.id} onClick={handleRoutesId}>
        {variant ? (
          <>
            <td className="second">{rowData?.senderFullName}</td>
            <td className="second">{rowData?.senderPhone}</td>
            <td className="second">{rowData?.senderAddressDetails}</td>
            <td className="second">{rowData?.description}</td>
            <td className="third">
              {/* passing the status component here */}
              <Status data={rowData?.orderStatus} />
            </td>
            <td className="third">
              <button className="status">
                <img
                  src="https://res.cloudinary.com/fastrack12/image/upload/v1661338865/Vector_4_tdmix9.svg"
                  alt="right arrow icon"
                />
              </button>
            </td>
          </>
        ) : (
          <>
            <td>{rowData?.fullName}</td>
            <td>{rowData?.email}</td>
            <td>{rowData?.phoneNumber}</td>
            <td>{rowData?.nationality}</td>
            <td>
              {/* passing the status component here */}
              <Status data={rowData?.verificationStatus || "PENDING"} />
            </td>
            <td>
              <button className="status">
                <img
                  src="https://res.cloudinary.com/fastrack12/image/upload/v1661338865/Vector_4_tdmix9.svg"
                  alt="right arrow icon"
                />
              </button>
            </td>
          </>
        )}
      </tr>
    </>
  );
};

export default DataRow;
