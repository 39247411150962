import { useLocation, useNavigate } from "react-router-dom";
import DeliveryStatus from "../../components/Status/deliveryStatus";
import "./index.scss";

const SubDeliveriesLayout = ({ backRouteName, children }) => {
  const location = useLocation();
  const navigate = useNavigate();

  // console.log(location)

  const handleBack = () => {
    navigate("/" + backRouteName);
  };

  const locationFunc = (value) => {
    const splitted = value.split('"formatted_address":"');
    const address = splitted[1];
    const slicedAddress = address.split('"}')[0];
    return slicedAddress;
  };
  // {locationFunc(location?.state?.pickupLocation)}

  return (
    <div className="subCategoriesContainer">
      <div className="topContainer">
        <div onClick={handleBack} className="goBackBtn">
          <img
            src="https://res.cloudinary.com/fastrack12/image/upload/v1661459504/Vector_5_htgbvd.svg"
            alt="left arrow icon"
          />
          <p>Go back</p>
        </div>

        <div className="profileContainer">
          {/* <div className="profileImageContainer">
                        <img src="https://res.cloudinary.com/fastrack12/image/upload/v1661459597/Frame_3537_zayfko.svg" alt="profile of user" />
                    </div> */}

          <div className="profileDescriptions">
            <div className="nameContainer">
              <p className="name">{location?.state?.senderFullName || "NA"}</p>
              {/* passing the delivery status component */}
              <DeliveryStatus data={location?.state?.orderStatus} />
            </div>
            <p className="registrationNumber">
              order number: <span> {location?.state?.id || "NA"}</span>
            </p>
          </div>
        </div>
      </div>

      {/* body content */}
      <div className="bottomContainer">
        <div className="items">
          <p className="title">Sender's Name</p>
          <p className="name">{location?.state?.senderFullName || "NA"}</p>
        </div>

        <div className="items">
          <p className="title">Phone Number</p>
          <p className="name">{location?.state?.senderPhone || "NA"}</p>
        </div>
      </div>

      <div className="bottomContainer">
        <div className="items">
          <p className="title">Pickup address</p>
          <p className="name">
            {locationFunc(location?.state?.pickupLocation) || "NA"}
          </p>
        </div>

        <div className="items">
          <p className="title">City/state</p>
          <p className="name">{location?.state?.city || "NA"}</p>
        </div>
      </div>

      <div className="bottomContainer">
        <div className="items">
          <p className="title">Receiver's name</p>
          <p className="name">{location?.state?.recipientFullName || "NA"}</p>
        </div>

        <div className="items">
          <p className="title">Receiver's number </p>
          <p className="name">
            {location?.state?.recipientPhoneNumber || "NA"}
          </p>
        </div>
      </div>

      <div className="bottomContainer">
        <div className="items">
            <p className="title">Package Cost</p>
            <p className="name">
              ₺{location?.state?.packageCost || "NA"}
            </p>
          </div>

        <div className="items">
          <p className="title">Package Value</p>
          <p className="name">{location?.state?.packageValue || "NA"}</p>
        </div>
      </div>

      <div className="bottomContainer">
        

        <div className="items">
          <p className="title">Receiver's address</p>
          <p className="name">
            {locationFunc(location?.state?.destinationLocation)}
          </p>
        </div>

        <div className="items">
          <p className="title">More details</p>
          <p className="name">{location?.state?.paymentMethod || "NA"}</p>
        </div>

        {/* <div className="items">
          <p className="title">More details</p>
          <p className="name">{location?.state?.paymentMethod || "NA"}</p>
        </div> */}
      </div>

      {/* the children i.e ReactNode elements goes in here if any */}
      {children}
    </div>
  );
};

export default SubDeliveriesLayout;
