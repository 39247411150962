import PasswordReset from '../../components/AuthForms/PasswordReset'
import AuthLayout from '../../layout/AuthLayout'

const ResetPassword = () => (
    <AuthLayout
        title="Forgot password"
    >
        <PasswordReset />
    </AuthLayout>
)


export default ResetPassword