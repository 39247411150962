import SignIn from '../../components/AuthForms'
import AuthLayout from '../../layout/AuthLayout'

const AdminAuthentication = () => (
    
    <AuthLayout
        title="Create new password"
        description="sign in to continue"
    >
        <SignIn />

    </AuthLayout>


)


export default AdminAuthentication