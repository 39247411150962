import {toast} from 'react-toastify'
import { useState } from 'react'
import './index.scss'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {loginPending, loginSuccess, loginFail } from "../../redux/Authentication/LoginSlice"
import { userLogin } from '../../api/authentication/authApi'
import { getUserProfile } from '../../redux/Dashboard/UserAction'
import FormInput from '../InputField/FormInput'

const SignIn = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const {isLoading} = useSelector(state => state.login)
    
    const [state, setState] = useState({
        email: "",
        password: ""
    })

    const onChange = (e) => {
        setState({
            ...state,
            [e.target.name] : e.target.value
        })
    }
    
   
    const submitFormData = async (e) => {
        e.preventDefault()
        dispatch(loginPending())        
        try {
            const resp = await userLogin(state)
            dispatch(loginSuccess())
            dispatch(getUserProfile())
            navigate('/dashboard') 
            toast.success("login successful")
        } catch (error) {
            dispatch(loginFail("Invalid email and password combination"))
            toast.error("Invalid email and password combination")
        }
        setState({
            email: "",
            password: ""
        })
    }

    
    
    const inputs = [
        {
            id: 1,
            type: "email",
            name: "email",
            placeholder: "janedoe@gmail.com",
            label: "Email",
            errorMessage: "email address is not valid",
            // pattern: ``
            required: true
        },

        {
            id: 2,
            type: "password",
            name: "password",
            placeholder: "*********",
            label: "Password",
            errorMessage: "Password should be 5-20 characters and should include 1 letter and 1 number",
            // pattern: `^(?=.*[0-9])(?=.*[a-zA-Z])[a-zA-Z0-9!@#$%^&*]{5,20}$`,
            required: false
        },
    ]

    return (
        <>            
            <form className="form" onSubmit={submitFormData}>                              
                {/* render input Components herre */}
                {inputs.map((input) => (
                    <FormInput
                        key={input.id}
                        {...input}
                        value={state[input.name]}
                        onChange={onChange}
                    />
                ))}
                
                <div className="input">
                    <Link to= "/forgot-password">
                        <label className='forgotPassword'>Forgot password?</label> 
                    </Link>
                </div>

                <button className="nextBtn">
                    { isLoading ? <div className="loadingz"></div> : "Sign in"}
                </button>

            </form>
        </>
    )
}

export default SignIn