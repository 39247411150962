import { useState } from 'react'
import {toast} from 'react-toastify'
import { createAdmin } from '../../../api/dashboard/userApi'
import './index.scss'


const AddUser = ({ cancelAdmin, handlePropagation }) => {
    const [load, setLoad] = useState(false)
    const [state, setState] = useState({
        name: "",
        email: ""
    })
    const [role, setRole] = useState("SUPER")
 
    const handleSubmit = async (e) => {
        setLoad(true)
        e.preventDefault()

        const stateValue = {
            ...state,
            role: role
        }

        try {
            const resp = await createAdmin(stateValue)
            setLoad(false)        
            toast.success("admin created successfully")
            if (resp) cancelAdmin()
        } catch (error) {
            setLoad(false)
            toast.error(error.response.data.message)
            cancelAdmin()
        }

    }

    const handleRoles = (e) => {
        const selectedValue = e.target.value
        setRole(selectedValue)
    }



    const handleChange = (e) => {
        const value = e.target.value
        setState({
            ...state,
            [e.target.name]: value
        })
    }


    return (
        <div
            className="modalContainer"
            onClick={cancelAdmin}
        >
            <form
                className="modalContent"
                onSubmit={handleSubmit}
                onClick={handlePropagation}
            >
                <p className='title'>Add a new Admin</p>

                <div className="input"> 
                    <label htmlFor="name">Admin Name</label> 
                    <input 
                        onChange={handleChange}
                        id="name"
                        name="name"
                        type="text"
                        placeholder="name"
                        value={state.name}
                        required
                    />
                </div>

                <div className="input">
                    <label htmlFor="email">Email Address</label> 
                    <input 
                        onChange={handleChange}
                        id="email"
                        name="email"
                        type="email"
                        placeholder="email"
                        value={state.email}
                        required
                    />
                </div>

                <div className="input">
                    <label htmlFor="role">Admin Role</label> 
                    <select
                        onChange={handleRoles}
                        className="select"
                        name="role"
                        id="role"
                        value={role}
                        required
                    >
                        <option value="SUPER" >SUPER ADMINISTRATOR</option>
                        <option value="ADMINISTRATOR">ADMINISTRATOR</option>
                    </select>

                </div>

                {load ? <div className="loading"></div> : (
                    <div className="buttonContainer">
                        <button onClick={cancelAdmin}>Cancel</button>
                        <button className="save">Save</button>
                    </div>
                )}
            </form>
        </div>
    )
}

export default AddUser