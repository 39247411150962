import { Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import axios from "axios";
import { update, ref, child, push, get } from "firebase/database";

import { db } from "../../../firebase/firebase";
import { addMessageData } from "../../../redux/Dashboard/AllUsersSlice";
import "./index.scss";

const ChatContainer = ({ userId, message, setSelectedId, selectedId }) => {
  const dispatch = useDispatch();
  const { otherInformation } = useSelector((state) => state.allUsers);
  const [newMessage, setNewMessage] = useState();

  const [color, setColor] = useState({
    bg1: "white",
    bg2: "transparent",
    color1: "1E1F20",
    color2: "#6D7580",
    boxShadow1: "0px 1px 4px rgba(0, 0, 0, 0.04)",
    boxShadow2: "none",
  });

  const handleSelect = (id) => {
    if (id === "1") {
      setSelectedId(id);
      setColor({
        bg1: "white",
        bg2: "transparent",
        color1: "1E1F20",
        color2: "#6D7580",
        boxShadow1: "0px 1px 4px rgba(0, 0, 0, 0.04)",
        boxShadow2: "none",
      });
    } else {
      setSelectedId(id);
      setColor({
        color1: "#6D7580",
        color2: "1E1F20",
        bg1: "transparent",
        bg2: "white",
        boxShadow1: "none",
        boxShadow2: "0px 1px 4px rgba(0, 0, 0, 0.04)",
      });
    }
  };

  const handleEndChat = () => {
    get(child(ref(db), `messages/${userId}`))
      .then((snapshot) => {
        if (snapshot.exists()) {
          snapshot.forEach((childSnapshot) => {
            const key = childSnapshot.key;

            update(child(ref(db), `messages/${userId}/${key}`), {
              isDeleted: true,
            })
              .then((val) => console.log({ val }))
              .catch((err) => console.log({ err }));
          });
        } else {
          console.log("No data available");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleOnChange = (e) => {
    setNewMessage(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (newMessage !== "") {
      setNewMessage("");
      axios
        .post(
          "https://us-central1-fastrack-logistiks.cloudfunctions.net/fastrack/send-message/",
          {
            userId: userId,
            chat: {
              message: newMessage,
              type: "cs",
              userId,
            },
          },
          {
            headers: {
              Authorization: `${process.env.REACT_APP_AUTHORIZATION}`,
            },
          }
        )
        .then((response) => {
          setNewMessage("");
        })
        .catch((error) => {
          dispatch(addMessageData(error));
        });
    }
  };

  return (
    <>
      <div className="header">
        <div className="nameContainer">
          <div className="firstLetterContainer">
            {otherInformation?.fullName?.slice(0, 1)}
          </div>
          <div className="profileContainer">
            <p className="name">{otherInformation?.fullName}</p>
            <p className="email">{otherInformation?.email}</p>
          </div>

          <div style={{ marginLeft: "auto" }}>
            <button onClick={handleEndChat}>End chat</button>
          </div>
        </div>

        <section className="bottomContainer">
          <nav className="chatNav">
            <section
              style={{
                background: `${color.bg1}`,
                color: `${color.color1}`,
                boxShadow: `${color.boxShadow1}`,
              }}
              className="selected"
              onClick={() => handleSelect("1")}
            >
              Chat
            </section>
            <section
              style={{
                background: `${color.bg2}`,
                color: `${color.color2}`,
                boxShadow: `${color.boxShadow2}`,
              }}
              onClick={() => handleSelect("2")}
            >
              Profile
            </section>
          </nav>
        </section>
      </div>

      {selectedId === "1" ? (
        <div className="chatContentContainer">
          <div className="chatContent">
            {message?.length !== 0 ? (
              message?.map((messages, index) => (
                <Fragment key={index}>
                  {messages?.chat?.type === "user" ? (
                    <div className="receiverContainer">
                      <div className="firstContainer">
                        <div className="firstLetterContainer">
                          {otherInformation?.fullName?.slice(0, 1)}
                        </div>
                        <div className="time">
                          {moment(
                            Number(messages?.chat?.timestamp * 1000)
                          ).format(" HH:mm A")}
                        </div>
                      </div>
                      <div className="profileContainer">
                        <p className="name" style={{ color: "#4F4F4F" }}>
                          {otherInformation?.fullName}
                        </p>
                        <div className="receiverMessage">
                          <p className="text">{messages?.chat?.message}</p>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="senderContainer">
                      <section>
                        <div className="profileContainer">
                          <p className="name" style={{ color: "#4F4F4F" }}>
                            Admin
                          </p>

                          <div className="senderMessage">
                            <p className="text">{messages?.chat?.message}</p>
                          </div>
                        </div>
                        <div className="firstContainer">
                          <div className="firstLetterContainer">SR</div>
                          <div className="time">
                            {moment(
                              Number(messages?.chat?.timestamp * 1000)
                            ).format(" HH:mm A")}
                          </div>
                        </div>
                      </section>
                    </div>
                  )}
                </Fragment>
              ))
            ) : (
              <div className="emptyChatContainer">
                Send A Message To Start A Conversation
              </div>
            )}
          </div>

          <div className="chatFooter">
            <form className="sendContainer" onSubmit={handleSubmit}>
              <div className="inputSend">
                <input
                  placeholder="Type message"
                  value={newMessage}
                  onChange={handleOnChange}
                />
                <div>
                  {/*<img src="https://res.cloudinary.com/fastrack12/image/upload/v1669782460/emoji_emotions_nt9xvn.svg" alt="emoji icon" />
                                     <img src="https://res.cloudinary.com/fastrack12/image/upload/v1669782460/Vector_tnd2uj.svg" alt="link icon" />
                                    <img src="https://res.cloudinary.com/fastrack12/image/upload/v1669782460/attach_file_g42uti.svg" alt="file icon" /> */}
                </div>
              </div>

              <button className="sendIcon">
                <img
                  src="https://res.cloudinary.com/fastrack12/image/upload/v1669782460/send_aibk7w.svg"
                  alt="send icon"
                />
              </button>
            </form>
          </div>
        </div>
      ) : (
        <div className="profilePage">
          <div className="letter">
            {otherInformation?.fullName?.slice(0, 1)}
          </div>
          <div className="nameEmail">
            <p className="names">{otherInformation?.fullName}</p>
            <p className="emails">{otherInformation?.email}</p>
          </div>

          <div className="personalInformation">
            <p>
              Name: <span>{otherInformation?.fullName}</span>
            </p>
            <p>
              Id: <span>{otherInformation?.id}</span>
            </p>
            <p>
              PhoneNumber: <span>{otherInformation?.phoneNumber}</span>
            </p>
            <p>
              Type:{" "}
              <span>
                {otherInformation?.type === undefined ? "USER" : "DRIVER"}
              </span>
            </p>
          </div>
        </div>
      )}
    </>
  );
};

export default ChatContainer;
