export const appTableHeader = [
    {
        id: 1,
        name: "Name"
    },
    {
        id: 2,
        name: "Email Address"
    },

    {
        id: 3,
        name: "Phone number"
    },

    {
        id: 4,
        name: "Country"
    },

    {
        id: 5,
        name: "Status"
    },

    {
        id: 6,
        name: ""
    }
]

export const deliveriesTableHeader = [
    {
        id: 1,
        name: "Sender's name"
    },
    {
        id: 2,
        name: "Phone number"
    },

    {
        id: 3,
        name: "Address"
    },

    {
        id: 4,
        name: "Package"
    },

    {
        id: 5,
        name: "Status"
    },

    {
        id: 6,
        name: ""
    }

]

export const DriverData = [
    {
        id: 1,
        img: "https://res.cloudinary.com/fastrack12/image/upload/v1661256051/supervisor_account_pivceo.svg",
        title: 'All Applications',
        link: "/dashboard"
    },

    {
        id: 2,
        img: "https://res.cloudinary.com/fastrack12/image/upload/v1661256053/approval_f1xhay.svg",
        title: 'Approved Applications',
        link: "/approved-applications"
    },

    {
        id: 3,
        img: "https://res.cloudinary.com/fastrack12/image/upload/v1661256053/Group_s9zrbs.svg",
        title: 'Pending Applications',
        link: "/pending-applications"
    },

    {
        id: 4,
        img: "https://res.cloudinary.com/fastrack12/image/upload/v1661256051/Vector_2_tshbnr.svg",
        title: 'Declined Applications',
        link: "/declined-applications"
    },
]

export const DeliveriesData = [
    {
        id: 1,
        img: "https://res.cloudinary.com/fastrack12/image/upload/v1661256053/Group_s9zrbs.svg",
        title: 'Pending Deliveries',
        link: "/pending-deliveries"
    },

    {
        id: 2,
        img: "https://res.cloudinary.com/fastrack12/image/upload/v1665580421/samples/2ongoing_ezq1wj.svg",
        title: 'Ongoing Deliveries',
        link: "/ongoing-deliveries"
    },

    {
        id: 3,
        img: "https://res.cloudinary.com/fastrack12/image/upload/v1665580421/samples/1completed_gaad3q.svg",
        title: 'Completed Deliveries',
        link: "/completed-deliveries"
    }
]

export const MoreData = [
    {
        id: 1,
        img: "https://res.cloudinary.com/fastrack12/image/upload/v1669704879/support_zucjqn.svg",
        title: 'Support Requests',
        link: "/support-request"
    }, 

    {
        id: 2,
        img: "https://res.cloudinary.com/fastrack12/image/upload/v1661256051/supervisor_account_pivceo.svg",
        title: 'Users',
        link: "/users"
    },



]


export const LogOutData = [
    {
        id: 1,
        img: "https://res.cloudinary.com/fastrack12/image/upload/v1661256051/logout_t5hwul.svg",
        title: 'Logout',
        link: "/logout"
    }
] 


