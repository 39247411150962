import DashboardContentLayout from "../../../layout/DashboardContentTopLayout";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import DataFilterByDate from "../../DataFilter";
import { appTableHeader } from "../../../utils/data";
import { onMessageListener } from "../../../firebase/firebase";

const AllAppContent = () => {
  const { drivers, appToday, appThisWeek, appThisMonth } = useSelector(
    (state) => state.driver
  );
  const [selected, setSelected] = useState("This year");
  const [show, setShow] = useState(false);
  const [notification, setNotification] = useState({ title: "", body: "" });

  useEffect(() => {
    onMessageListener()
      .then((payload) => {
        setShow(true);
        setNotification({
          title: payload.notification.title,
          body: payload.notification.body,
        });
      })
      .catch((err) => {});
  }, []);

  return (
    <>
      <DashboardContentLayout
        title="All Applications"
        selected={selected}
        setSelected={setSelected}
      />

      {/* The Filter Data By Date component receives four parameters
                the selected dropdown data, today's queried data as well as 
                this week, month and year queried data
            */}

      {drivers ? (
        <DataFilterByDate
          word="applications"
          routeName="dashboard"
          selected={selected}
          todayData={appToday}
          thisWeekData={appThisWeek}
          thisMonthData={appThisMonth}
          thisYearData={drivers}
          typeOfTable={appTableHeader}
        />
      ) : (
        "Loading"
      )}
    </>
  );
};

export default AllAppContent;
