import axios from "axios"
import { DELIVERIES } from "../apiRoutes"

const baseURL =  "https://fastrack-app.herokuapp.com/api/order/"

export const instance = axios.create({
    baseURL,
    timeout: 300000,
    
    headers: {
        "Access-Control-Allow-Origin": "*",
        "content-type": "application/json",
        "X-Fastrack-key": `${process.env.REACT_APP_FASTRACK_KEY}`,
    }
})




export const checkAllAvailableDriverToADelivery = (id) => {
    return new Promise(async (resolve, reject) => { 
        try {                        
            const data = {
                orderId: id
            }
            const resp = await instance.post("available-driver/details", data)
            
            resolve(resp)

        } catch ( error ) {
            
            reject(error)
        }
    })
}


export const getPendingDeliveries = () => {
    return new Promise(async (resolve, reject) => { 
        try {
            const resp = await instance.get( DELIVERIES.pendingDeliveries)
            resolve(resp)

        } catch ( error ) {
            reject(error)
        }
    })
}

export const getPendingDeliveriesThisDay = () => {
    return new Promise(async (resolve, reject) => { 
        try {
            const resp = await instance.get(`${DELIVERIES.pendingDeliveries}?days=1`)
            resolve(resp)
        } catch ( error ) {
            reject(error)
        }
    })
}

export const getPendingDeliveriesThisWeek = () => {
    return new Promise(async (resolve, reject) => { 
        try {
            const resp = await instance.get(`${DELIVERIES.pendingDeliveries}?days=8`)
            resolve(resp)

        } catch ( error ) {
            reject(error)
        }
    })
}


export const getPendingDeliveriesThisMonth = () => {
    return new Promise(async (resolve, reject) => { 
        try {   
            const resp = await instance.get(`${DELIVERIES.pendingDeliveries}?months=1`)
            resolve(resp)

        } catch ( error ) {
            reject(error)
        }
    })
}

// ongoing

export const getOngoingDeliveries = () => {
    return new Promise(async (resolve, reject) => { 
        try {
            const resp = await instance.get( DELIVERIES.ongoingDeliveries)
            resolve(resp)

        } catch ( error ) {
            reject(error)
        }
    })
}


export const getOngoingDeliveriesThisDay = () => {
    return new Promise(async (resolve, reject) => { 
        try {
            const resp = await instance.get(`${DELIVERIES.ongoingDeliveries}?days=1`)
            resolve(resp)
        } catch ( error ) {
            reject(error)
        }
    })
}

export const getOngoingDeliveriesThisWeek = () => {
    return new Promise(async (resolve, reject) => { 
        try {
            const resp = await instance.get(`${DELIVERIES.ongoingDeliveries}?days=8`)
            resolve(resp)

        } catch ( error ) {
            reject(error)
        }
    })
}


export const getOngoingDeliveriesThisMonth = () => {
    return new Promise(async (resolve, reject) => { 
        try {   
            const resp = await instance.get(`${DELIVERIES.ongoingDeliveries}?months=1`)
            resolve(resp)

        } catch ( error ) {
            reject(error)
        }
    })
}


// Completed
export const getCompletedDeliveries = () => {
    return new Promise(async (resolve, reject) => { 
        try {
            const resp = await instance.get( DELIVERIES.completedDeliveries)
            resolve(resp)

        } catch ( error ) {
            reject(error)
        }
    })
}

export const getCompletedDeliveriesThisDay = () => {
    return new Promise(async (resolve, reject) => { 
        try {
            const resp = await instance.get(`${DELIVERIES.completedDeliveries}?days=1`)
            resolve(resp)
        } catch ( error ) {
            reject(error)
        }
    })
}

export const getCompletedDeliveriesThisWeek = () => {
    return new Promise(async (resolve, reject) => { 
        try {
            const resp = await instance.get(`${DELIVERIES.completedDeliveries}?days=8`)
            resolve(resp)

        } catch ( error ) {
            reject(error)
        }
    })
}


export const getCompletedDeliveriesThisMonth = () => {
    return new Promise(async (resolve, reject) => { 
        try {   
            const resp = await instance.get(`${DELIVERIES.completedDeliveries}?months=1`)
            resolve(resp)

        } catch ( error ) {
            reject(error)
        }
    })
}




