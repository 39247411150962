import { useState } from "react"
import SubCategoryLayout from "../../../layout/CategoriesLayout"
import { useNavigate, useLocation } from "react-router-dom"
import { useDispatch } from "react-redux"
import { toast } from "react-toastify"
import { getAllApprovedDriverApplications } from "../../../redux/Dashboard/DriverAction"
import { deleteDriver } from "../../../api/dashboard/applicationApi"
import ConfirmationPopup from "../../PopupModal"

const ApprovedAppSubcategory = () => {
    const [removeDriver, setRemoveDriver] = useState(false)
    const [load, setLoad] = useState(false)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const id = location.state.id

    const handleDeleteDriver = async (id) => {
        setLoad(true)
        // console.log(id)
        try {
            const response = await deleteDriver(id)
            // console.log(response?.data)
            if (response) {
                dispatch(getAllApprovedDriverApplications())
                setTimeout(() => {
                    navigate("/approved-applications")
                    toast.success("Driver Account Deleted")
                }, 1000)
            }
            setLoad(false)
        } catch (error) {
            // console.log(error)
            if (error.response.status === 401) {
                toast.error(error?.response?.data?.message)
            } else {
                toast.error("something went wrong")
            }

            toast.error("something went wrong")
            setLoad(false)
        }
    }

    const handleClose = () => {
        setRemoveDriver(!removeDriver)
    }

    return (
        <SubCategoryLayout backRouteName="approved-applications">
            <div className="buttonContainer">
                <button onClick={handleClose} style={{ background: "red" }}>
                    Remove Driver
                </button>
            </div>

            {removeDriver && (
                <ConfirmationPopup
                    cancelFunction={handleClose}
                    load={load}
                    text="Are You sure you want to REMOVE this driver from Fastrack?"
                    cancelButtonText="No"
                    confirmButtonText="Yes"
                    saveFunction={() => handleDeleteDriver(id)}
                />
            )}
        </SubCategoryLayout>
    )
}

export default ApprovedAppSubcategory
