import PendingAppSubcategory from "../../../components/Categories/PendingApp"
import DashboardLayout from "../../../layout/DashboardLayout"


const PendingAppChild = () => (
    <DashboardLayout>
        <PendingAppSubcategory />
    </DashboardLayout>
)


export default PendingAppChild