import './index.scss'

const Pagination = ({
    currentPage,
    handleNext,
    handlePrevious,
    renderPageNumbers
}) => {

    return (
        <div className="paginationContainer">
            <button>{currentPage}</button> <span>of &nbsp;{renderPageNumbers.length}</span>
            <button
                className={`leftArrow ${currentPage === 1 ? "disabled" : ""}`}
                onClick={handlePrevious}
                disabled={currentPage === 1}
            >
                <img src="https://res.cloudinary.com/fastrack12/image/upload/v1661338865/Vector_4_tdmix9.svg" alt="left arrow icon" />
            </button>
            
            <button
                className={`rightArrow ${currentPage === renderPageNumbers.length ? "disabled" : ""}`}
                onClick={handleNext}
                disabled={currentPage === renderPageNumbers.length}
            >
                <img src="https://res.cloudinary.com/fastrack12/image/upload/v1661338865/Vector_4_tdmix9.svg" alt="right arrow icon" />
            </button>
        </div>
    )
}

export default Pagination