import OngoingDeliveriesCategory from "../../../components/Categories/OngoingDeliveries"
import DashboardLayout from "../../../layout/DashboardLayout"



const OngoingDeliveriesChild = () => (
    <DashboardLayout>
        <OngoingDeliveriesCategory/>
    </DashboardLayout>
)


export default OngoingDeliveriesChild