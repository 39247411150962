import { combineReducers } from "@reduxjs/toolkit"
import loginReducer from "../redux/Authentication/LoginSlice"
import userReducer from "../redux/Dashboard/UserSlice"
import driverReducer from "../redux/Dashboard/DriverSlice"
import orderReducer from "../redux/Dashboard/OrderSlice"
import adminReducer from "../redux/Dashboard/AdminSlice"
import allUsersReducer from "./Dashboard/AllUsersSlice"


export const reducer = combineReducers({
    login: loginReducer,
    user: userReducer,
    driver: driverReducer,
    order: orderReducer,
    admin: adminReducer,
    allUsers: allUsersReducer
})