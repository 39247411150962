import { useState } from 'react';
import AdminRow from './AdminRow'
import Pagination from './Pagination'


const headerName = [
    {
        id: 1,
        name: 'Name',
    },

    {
        id: 2,
        name: 'Role',
    },

    {
        id: 3,
        name: 'Status',
    },

    {
        id: 4,
        name: '',
    }    
]

const AdminTable = ({ admin, page, editAdmin, handleConfirmation , nextPage, prevPage}) => {
    
    const [search, setSearch] = useState("")
    return (
        <>
            <div className="searchContainer">
                <img src="https://res.cloudinary.com/fastrack12/image/upload/v1661337462/search_tumu79.svg" alt="search icon" />
                <input
                    type="text"
                    placeholder="Search name to get admin"
                    onChange={(e) => setSearch(e.target.value)}
                />
            </div>


            
            <div className='tableContainer'>
                <table>
                    <thead>
                        <tr className="header">
                            {headerName.map(header => (
                                <th key={header.id} >{ header.name }</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        <AdminRow
                            search={search}
                            admin={admin}
                            editAdmin={editAdmin}
                            handleConfirmation={handleConfirmation}
                        />
                    </tbody>
                    
                </table>
            </div>

            <Pagination 
                currentPage={page}
                handleNext={nextPage}
                handlePrevious={prevPage}
                renderPageNumbers={admin?.data}
                admin={admin}
            />    
        </>
    )
}

export default AdminTable